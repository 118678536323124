import React from "react";
import { Button, Col, Row, Modal } from "antd";

import axios from "axios";

import endpoints from "../../../services/endpoints";

import "../../../assets/styles/admin.css";
import "react-quill/dist/quill.snow.css";

function Update({ open, close, data }) {
  function handleClose() {
    close();
  }

  function handleSubmit() {
    axios
      .post(endpoints.banners.delete, {
        data: data.id,
      })
      .then((res) => {
        setTimeout(() => {
          handleClose();
        }, 1000);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <Modal
      className="modal-delete-banner p-20"
      key={`modal-delete-banner-${data.id}`}
      title="Apagar banner"
      width={800}
      onCancel={handleClose}
      open={open}
      footer={[
        <Button onClick={handleClose}>Cancelar</Button>,
        <Button type="primary" onClick={handleSubmit}>
          Apagar
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <p>Deseja mesmo apagar o banner:</p>
          <p className="f-26 mt-10">{data.title}</p>
        </Col>
      </Row>
    </Modal>
  );
}

export default Update;
