import React, { useEffect, useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Select, Input, Drawer, DatePicker } from "antd";
import axios from "axios";
import dayjs from "dayjs";

import endpoints from "../../../services/endpoints";
import CreateTag from "../tag/Create";

import "../../../assets/styles/admin.css";

function Update({ open, close, data, tags }) {
  const [previewLink, setPreviewLink] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isOpenCreateTag, setIsOpenCreateTag] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (open && Object.keys(data).length > 0) {
      data.date = dayjs(data.date);
      data.tags = data.tags ? JSON.parse(data.tags) : null;
      setPreviewLink(data.link);
      form.setFieldsValue(data);
    }
  }, [open]);

  function handleSubmit(values) {
    setIsButtonLoading(true);
    values.date = dayjs(values.date).format("YYYY-MM-DD");
    values.tags = values.tags ? JSON.stringify(values.tags) : null;
    axios
      .post(endpoints.podcasts.update, {
        data: values,
      })
      .then((res) => {
        setIsButtonLoading(false);
        form.resetFields();
        close();
      })
      .catch((e) => {
        setIsButtonLoading(false);
        console.log(e);
      });
  }

  function handleClose() {
    form.resetFields();
    close();
  }

  function handlePreviewLink(e) {
    setPreviewLink(e.target.value);
  }

  function handleOpenCreateTag() {
    setIsOpenCreateTag(true);
  }

  function handleCloseCreateTag(data) {
    if (data) {
      let auxTags = tags;
      auxTags.push(data);

      auxTags.sort((a, b) => {
        let fa = a.name.toLowerCase();
        let fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      let tagsForm = form.getFieldValue("tags");
      tagsForm.push(data.id);
      form.setFieldValue("tags", tagsForm);
    }
    setIsOpenCreateTag(false);
  }

  return (
    <Drawer
      className="drawer-podcast"
      key={`drawer-create-podcast`}
      title="Editar podcast"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Button disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
            Cancelar
          </Button>
          <Button loading={isButtonLoading} type="primary" onClick={form.submit}>
            Editar
          </Button>
        </>
      }
    >
      <CreateTag open={isOpenCreateTag} close={handleCloseCreateTag} />
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item
          label="Título"
          name="title"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input size="large" placeholder="Título" />
        </Form.Item>

        <Row gutter={[24]}>
          <Col span={22}>
            <Form.Item label="Tags" name="tags">
              <Select
                mode="multiple"
                size="large"
                showSearch
                placeholder="Tags"
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                options={tags.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </Form.Item>
          </Col>
          <Col span={2} className="d-flex jc-end ai-center">
            <PlusCircleOutlined onClick={handleOpenCreateTag} style={{ fontSize: 20 }} />
          </Col>
        </Row>
        <Form.Item
          label="Data"
          name="date"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <DatePicker
            size="large"
            placeholder="Data"
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentNode;
            }}
          />
        </Form.Item>
        <Form.Item
          label="Link"
          name="link"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input size="large" placeholder="Link" onChange={handlePreviewLink} />
        </Form.Item>

        {previewLink != "" && (
          <iframe
            style={{ borderRadius: "12px" }}
            src={`${previewLink}?utm_source=generator&theme=0`}
            width="100%"
            height="152"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          />
        )}
      </Form>
    </Drawer>
  );
}

export default Update;
