import React, { useState } from "react";
import { Button, Form, InputNumber, Drawer, Input, Divider } from "antd";
import axios from "axios";
import { MinusCircleOutlined, PlusOutlined, ArrowUpOutlined } from "@ant-design/icons";

import endpoints from "../../../services/endpoints";

import "../../../assets/styles/admin.css";
import "react-quill/dist/quill.snow.css";

function Create({ open, close, id_guide }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleClose() {
    form.resetFields();
    close();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.guides.createModule, {
        data: { ...values, id_guide },
      })
      .then((res) => {
        setTimeout(() => {
          setIsButtonLoading(false);
          handleClose();
          form.resetFields();
        }, 1000);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Drawer
      className="drawer-module-guide"
      key={`drawer-create-module-guide`}
      title="Criar módulo"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Button disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
            Cancelar
          </Button>
          <Button loading={isButtonLoading} type="primary" onClick={form.submit}>
            Criar
          </Button>
        </>
      }
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical" initialValues={{ topics: [{ title: null }] }}>
        <Form.Item
          label="Posição"
          name="position"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <InputNumber className="w-100" min={0} size="large" placeholder="Posição" />
        </Form.Item>
        <Form.Item
          label="Título"
          name="title"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Título" size="large" />
        </Form.Item>

        <Divider />

        <Form.List name="topics">
          {(fields, { add, remove, move }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item label={index === 0 ? "Tópicos" : ""} required={false} key={field.key} className="guide_module_form_item">
                  <div
                    className="d-flex jc-center ai-center"
                    style={{
                      minWidth: "30px",
                      maxWidth: "30px",
                    }}
                  >
                    {index + 1}
                  </div>
                  <Form.Item
                    {...field}
                    validateTrigger={["onChange", "onBlur"]}
                    name={[index, "title"]}
                    sizelarge
                    rules={[
                      {
                        required: true,
                        message: "Este campo é obrigatório",
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      size="large"
                      placeholder="Tópico..."
                      style={{
                        width: "calc(100% - 110px)",
                      }}
                    />
                  </Form.Item>
                  {fields.length > 1 ? <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} /> : null}
                  {index !== 0 && fields.length > 1 ? <ArrowUpOutlined className="dynamic-delete-button" onClick={() => move(index, index - 1)} /> : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} className="w-100" size="large" icon={<PlusOutlined />}>
                  Adicionar tópico
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Drawer>
  );
}

export default Create;
