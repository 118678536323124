import React, { useEffect, useState } from "react";
import { Button, Drawer, Menu } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CloseOutlined, FacebookFilled, InstagramOutlined, LoginOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";

import Logo from "../../assets/images/logo-cardio4all.svg";

function getItem(label, key, children) {
  return {
    label,
    key,
    children,
  };
}

function DrawerMenuMobile({ user, open, close, handleLogout, menuItems }) {
  const navigate = useNavigate();
  const location = useLocation();
  let pathname = location.pathname.split("/");

  const [userData, setUserData] = useState(null);
  const [current, setCurrent] = useState(pathname[pathname.length - 1]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (location.pathname !== "/") {
      setCurrent(location.pathname);
    } else {
      setCurrent(`/`);
    }

    setUserData(user);
    setItems(menuItems);
  }, [user, location, menuItems]);

  function handleClickLogout() {
    close();
    handleLogout();
  }

  return (
    <Drawer
      closeIcon={null}
      className="drawer-menu-mobile"
      key={`drawer-menu-mobile`}
      width={"90%"}
      onClose={close}
      open={open}
      bodyStyle={{
        padding: 0,
      }}
      title={
        <div className="w-100">
          <div className="minh-85 maxh-85 h-100 d-flex">
            {userData ? (
              <div className="d-flex jc-sb ai-center w-100">
                <div>
                  <Button type="text" onClick={handleClickLogout}>
                    <span className="f-16 d-flex jc-center ai-center">
                      <LogoutOutlined className="f-16 mr-5" /> Logout
                    </span>
                  </Button>
                  <Link to="/perfil">
                    <Button type="text">
                      <span className="f-16 d-flex jc-center ai-center">
                        <UserOutlined className="f-16 mr-5" /> Perfil
                      </span>
                    </Button>
                  </Link>
                </div>
                <div>
                  <Button type="text" onClick={close}>
                    <span className="f-16 d-flex jc-center ai-center">
                      <CloseOutlined className="f-16 mr-5" />
                    </span>
                  </Button>
                </div>
              </div>
            ) : (
              <div className="d-flex jc-sb ai-center w-100">
                <div>
                  <Link to="/login">
                    <Button type="text">
                      <span className="f-16 d-flex jc-center ai-center">
                        <LoginOutlined className="f-16 mr-5" /> Login
                      </span>
                    </Button>
                  </Link>
                </div>
                <div>
                  <Button type="text" onClick={close}>
                    <span className="f-16 d-flex jc-center ai-center">
                      <CloseOutlined className="f-16" />
                    </span>
                  </Button>
                </div>
              </div>
            )}
          </div>
          <a href="./">
            <img src={Logo} alt="Logo" className="maxh-50 h-100 w-100" />
          </a>
        </div>
      }
    >
      <div className="d-flex flex-column jc-sb h-100 pl-20 pr-20 pt-20 pb-20 overflow-auto">
        <div className="d-flex flex-column jc-center ai-center">
          <Menu className="w-100 d-flex flex-column jc-center ai-center menu_mobile" mode="inline" selectedKeys={[current]} items={items} />
        </div>
        <div className="d-flex flex-column mt-30">
          <div className="d-flex flex-column jc-center ai-center">
            <div className="d-flex  jc-center ai-center">
              <FacebookFilled onClick={() => window.open("https://www.facebook.com/cardio4all.pt/")} className="mr-10" style={{ fontSize: 25, color: "#0E426A" }} />
              <InstagramOutlined onClick={() => window.open("https://www.instagram.com/cardio_4all/")} style={{ fontSize: 25, color: "#0E426A" }} />
            </div>
            <a className="blue mt-10" href="mailto:cardio4all@gmail.com">
              <u>cardio4all@gmail.com</u>
            </a>
          </div>
          <div className="mt-30">
            <p className="f-12 text-center">© Copyright 2024 | Cardio4all | Todos os direitos reservados</p>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default DrawerMenuMobile;
