import React, { useEffect, useState } from "react";
import { Col, Row, Button, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import ImageCoverBg from "../../../assets/images/bg-perfil@2x.png";
import endpoints from "../../../services/endpoints";
import GoogleReCAPTCHA from "../../../services/recaptcha";

import "./ResetPasswordEmail.css";

const ResetPasswordEmail = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  useEffect(() => {
    handleVerifyUser();
  }, [user]);

  function handleVerifyUser() {
    if (user) {
      navigate("/perfil");
    }
  }

  function handleLogin(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.auth.resetPasswordEmail, {
        data: values,
      })
      .then((res) => {
        setIsButtonLoading(false);
        if (res.data.email_exists) {
          message.success({
            content: "Foi enviado um e-mail com um link para a colocação de uma nova password. Se não receber um link num espaço de 10 minutos tente novamente por favor!",
          });
        } else {
          message.error({
            content: "Este e-mail não existe na nossa plataforma. Verifique se está a colocar o e-mail correto.",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
      });
  }

  const [isVerified, setIsVerified] = useState(true);
  const handleVerify = (value) => {
    setIsVerified(!!value);
  };

  return (
    <>
      <GoogleReCAPTCHA onVerify={handleVerify} />
      <Row
        className="h-100 w-100 login_content"
        style={{
          minHeight: "calc(100vh - 150px)",
          backgroundImage: `url(${ImageCoverBg})`,
        }}
      >
        <Col span={24} className="container d-flex flex-column jc-center ai-center">
          <Row className="login-row d-flex jc-center ai-center">
            <Col xs={24} sm={24} md={12} lg={12} xl={8} className="d-flex flex-column jc-center ai-center">
              <p className="f-20 blue mb-30 text-center">
                Recupere aqui a sua{" "}
                <b>
                  <i>password</i>
                </b>{" "}
                para poder aceder à sua conta.
              </p>
              <Form className="login-form w-100" name="basic" onFinish={handleLogin} form={form}>
                <Form.Item
                  size="large"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Introduza um endereço de e-mail válido",
                    },
                  ]}
                >
                  <Input size="large" placeholder="E-mail" />
                </Form.Item>
                <div className="d-flex jc-center ai-center">
                  <Button loading={isButtonLoading} type="primary" htmlType="submit" className="login-btn mt-20" disabled={!isVerified}>
                    Recuperar Password
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ResetPasswordEmail;
