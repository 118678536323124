import React, { useState, useEffect } from "react";
import { Button, Col, Form, Drawer, Row, Divider, Tooltip } from "antd";
import axios from "axios";
import { MinusCircleOutlined, PlusOutlined, ArrowUpOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";

import endpoints from "../../../services/endpoints";

import "react-quill/dist/quill.snow.css";
import "../../../assets/styles/admin.css";

function KeyMessages({ open, close, data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (open && data) {
      handleGetData();
    }
  }, [open]);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.clinicalCases.readOrientationById, {
        params: { id: data.id },
      })
      .then((res) => {
        let auxData = res.data[0][0];
        if (auxData.key_messages) {
          form.setFieldsValue({ key_messages: JSON.parse(auxData.key_messages) });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios
      .post(endpoints.clinicalCases.createOrientation, {
        keyMessages: values.key_messages,
        id_clinical_case: data.id,
      })
      .then((res) => {
        setIsButtonLoading(false);
        handleClose();
      })
      .catch((err) => {
        setIsButtonLoading(false);
        console.log(err);
      });
  }

  function handleClose() {
    close();
    form.resetFields();
  }

  return (
    <Drawer
      className="drawer-clinical"
      key={`drawer-create-questions-clinical-guidelines`}
      title="Mensagem-chave - Caso clínico"
      width={800}
      onClose={() => handleClose()}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Button disabled={isButtonLoading} className="mr-10" onClick={() => handleClose()}>
            Cancelar
          </Button>
          <Button loading={isButtonLoading} type="primary" onClick={form.submit}>
            Submeter
          </Button>
        </>
      }
    >
      <Row gutter={[24]} className="m-0">
        <Col span={24}>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.List name={"key_messages"}>
              {(fields, { add, remove, move }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <div style={{ position: "relative" }}>
                      <Col span={24}>
                        <Form.Item
                          {...field}
                          label="Texto"
                          name={[index, "text"]}
                          rules={[
                            {
                              required: true,
                              message: "Este campo é obrigatório",
                            },
                          ]}
                        >
                          <ReactQuill placeholder="Texto..." size="large" />
                        </Form.Item>
                      </Col>
                      <div className="d-flex jc-center ai-center" style={{ position: "absolute", top: 0, right: 0 }}>
                        {index !== 0 && fields.length > 1 ? (
                          <Tooltip title="Mover" placement="bottom">
                            <ArrowUpOutlined className="f-20 mr-10" onClick={() => move(index, index - 1)} />
                          </Tooltip>
                        ) : null}
                        {fields.length > 1 ? (
                          <Tooltip title="Apagar">
                            <MinusCircleOutlined className="f-20 mr-10" onClick={() => remove(field.name)} />
                          </Tooltip>
                        ) : null}
                      </div>
                      <Divider
                        style={{
                          height: 1,
                          backgroundColor: "rgba(0,0,0,0.2)",
                        }}
                      />
                    </div>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} className="w-100" size="large" icon={<PlusOutlined />}>
                      Adicionar item
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </Col>
      </Row>
    </Drawer>
  );
}

export default KeyMessages;
