import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Col, Row, Button, Form, Empty } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

import "./ListVideocasts.css";

const GuidesTab = ({ data, guides, handleOpenModalVideo }) => {
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    handleFilter(selectedFilter);
  }, [data]);

  const [itemsToShow, setItemsToShow] = useState(9);

  const handleLoadMore = () => {
    setItemsToShow(itemsToShow + 10);
  };

  function handleFilter(e) {
    console.log(e);
    if (e !== 0) {
      const newFilteredData = data.filter((item) => item.id_guide === e);
      setFilteredData(newFilteredData);
    } else {
      setFilteredData(data);
    }

    setSelectedFilter(e);
  }

  return (
    <Row
      gutter={[
        { xs: 40, sm: 8, md: 16, lg: 24 },
        { xs: 40, sm: 8, md: 16, lg: 24 },
      ]}
      className="mr-0 ml-0 d-flex"
    >
      <Col span={24}>
        <Row>
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={30}
            grabCursor={true}
            pagination={{
              clickable: true,
            }}
            className="swiper_videocasts_guides d-flex jc-start ai-start"
          >
            <SwiperSlide>
              <Button type={`${selectedFilter === 0 ? "primary" : "default"}`} value={0} onClick={() => handleFilter(0)}>
                Todos
              </Button>
            </SwiperSlide>
            {guides?.map((item) => (
              <SwiperSlide>
                <Button type={`${selectedFilter === item.id ? "primary" : "default"}`} value={item.id} onClick={() => handleFilter(item.id)}>
                  {item.title}
                </Button>
              </SwiperSlide>
            ))}
          </Swiper>
        </Row>
        {filteredData.length > 0 ? (
          <Row
            className="mt-40"
            gutter={[
              { xs: 40, sm: 8, md: 16, lg: 24 },
              { xs: 40, sm: 8, md: 16, lg: 24 },
            ]}
          >
            {filteredData?.slice(0, itemsToShow).map((item, index) => {
              return (
                <Col xs={24} sm={12} md={12} lg={8} xl={8} className="d-flex flex-column mb-20">
                  <div className="player-wrapper">
                    <ReactPlayer className="react-player" controls volume={1} url={item?.link} />
                  </div>
                  <p className="bold blue f-18 mb-10 mt-10">{item?.title}</p>
                  <p className="f-16 normal ellipsis_list_description">{item?.guide_title}</p>
                  {item?.button_text && item?.button_link && (
                    <Row className="mt-10">
                      <Col span={24} align="middle">
                        <Link to={item.button_link} target="_blank">
                          <Button type="primary" size="large">
                            {item.button_text}
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  )}
                </Col>
              );
            })}
            <Row className="d-flex jc-center ai-center mt-40 mb-40">
              {data && itemsToShow < data?.length && (
                <Button type="primary" size="large" onClick={handleLoadMore}>
                  Carregar mais
                </Button>
              )}
            </Row>
          </Row>
        ) : (
          <Empty description="Não foram encontrados guias" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Col>
    </Row>
  );
};

export default GuidesTab;
