import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Col, Form, Input, Row, Spin } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import ReactPlayer from "react-player";

import endpoints from "../../../services/endpoints";
import config from "../../../services/config";

import SearchIcon from "../../../assets/images/search-icon.svg";
import "./TagFilter.css";

function TagFilter() {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [videos, setVideos] = useState([]);
  const [podcasts, setPodcasts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchParams] = useSearchParams();

  const [formArticles] = Form.useForm();
  const [formVideos] = Form.useForm();
  const [formPodcasts] = Form.useForm();

  useEffect(() => {
    handleGetTagItems();
  }, []);

  function handleGetTagItems() {
    axios
      .get(endpoints.tags.filter, {
        params: { tag: searchParams.get("tag") },
      })
      .then((res) => {
        setArticles(res.data[0]);
        setFilteredArticles(res.data[0]);
        setPodcasts(res.data[1]);
        setVideos(res.data[2]);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleFilterArticles(e) {
    let string = e.title.toLowerCase();
    let filtred = articles.filter((item) => item.title.toLowerCase().includes(string));
    setFilteredArticles(filtred);
  }

  function handleFilterPodcasts(e) {
    console.log(e.title);
  }

  function handleFilterVideos(e) {
    console.log(e.title);
  }

  return (
    <Spin spinning={isLoading}>
      <Helmet>
        <title>New Science 4all | O blog do Cardio4all com a atualidade útil para a prática clínica</title>
        <meta name="description" content="O Cardio4all tem à sua disposição várias calculadoras úteis para a prática clínica MGF." />
        <meta
          name="keywords"
          content="New Science 4all, Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="New Science 4all | O blog do Cardio4all com a atualidade útil para a prática clínica" />
        <meta property="og:description" content="O Cardio4all tem à sua disposição várias calculadoras úteis para a prática clínica MGF." />
        <meta property="og:image" content={`${config.server_ip}/assets/media/${articles[0]?.image_banner}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Row className="container">
        <Col span={24} className="mt-40 d-flex jc-center ai-center">
          <div className="tag_selected text-center">#{searchParams.get("tag")}</div>
        </Col>
        {articles.length > 0 && (
          <Col span={24} className="mt-40 d-flex flex-column jc-center ai-center mb-80">
            <Row className="d-flex jc-sb">
              <Col span={12}>
                <p className="f-30 blue bold">Artigos New Science4All</p>
              </Col>
              <Col span={8} className="d-flex flex-column jc-center ai-end">
                <Form form={formArticles} onValuesChange={handleFilterArticles} className="w-100">
                  <Row className="d-flex jc-center ai-center flex-nowrap">
                    <img src={SearchIcon} width={"25px"} alt="search icon" className="mr-10" />
                    <Form.Item name="title" className="mb-0 w-100">
                      <Input size="large" placeholder="Procurar artigo" />
                    </Form.Item>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row
              gutter={[
                { xs: 20, sm: 8, md: 16, lg: 60 },
                { xs: 40, sm: 8, md: 16, lg: 40 },
              ]}
              className="mt-60"
            >
              {filteredArticles.map((item, index) => {
                return (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="article_list_item" key={"article-" + item?.id}>
                    <Row gutter={[12]}>
                      <Col span={8}>
                        <div
                          className="list-articles-div-img"
                          style={{
                            backgroundImage: `url(${config.server_ip}/assets/media/${item.image_thumbnail})`,
                          }}
                        ></div>
                      </Col>
                      <Col span={16} className="d-flex flex-column jc-sb ai-start">
                        <div>
                          <h2 className="normal f-26 mb-10 ellipsisTwo">{item?.title}</h2>
                          <p className="f-16 normal ellipsis_list_description">{item?.description}</p>
                        </div>
                        <div>
                          <Link to={`/artigos/${item.slug}`} className="ler-artigo-completo">
                            Ler artigo completo
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
          </Col>
        )}

        {podcasts.length > 0 && (
          <Col span={24} className="d-flex flex-column jc-center ai-center mb-80">
            <Row className="d-flex jc-sb">
              <Col span={12}>
                <p className="f-30 blue bold">Podcasts</p>
              </Col>
              <Col span={8} className="d-flex flex-column jc-center ai-end">
                <Form form={formPodcasts} onValuesChange={handleFilterPodcasts} className="w-100">
                  <Row className="d-flex jc-center ai-center flex-nowrap">
                    <img src={SearchIcon} width={"25px"} alt="search icon" className="mr-10" />
                    <Form.Item name="title" className="mb-0 w-100">
                      <Input size="large" placeholder="Procurar podcast" />
                    </Form.Item>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row
              gutter={[
                { xs: 20, sm: 8, md: 16, lg: 60 },
                { xs: 40, sm: 8, md: 16, lg: 40 },
              ]}
              className="mt-60"
            >
              {podcasts.map((podcast, index) => {
                return (
                  <Col span={8} key={"article-" + podcast?.id}>
                    <iframe
                      style={{ borderRadius: "12px" }}
                      src={`${podcast.link}?utm_source=generator&theme=0`}
                      width="100%"
                      height="152"
                      frameBorder="0"
                      allowfullscreen=""
                      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                      loading="lazy"
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        )}

        {videos.length > 0 && (
          <Col span={24} className="mt-40 d-flex flex-column jc-center ai-center">
            <Row className="d-flex jc-sb">
              <Col span={12}>
                <p className="f-30 blue bold">Podcasts</p>
              </Col>
              <Col span={8} className="d-flex flex-column jc-center ai-end">
                <Form form={formVideos} onValuesChange={handleFilterVideos} className="w-100">
                  <Row className="d-flex jc-center ai-center flex-nowrap">
                    <img src={SearchIcon} width={"25px"} alt="search icon" className="mr-10" />
                    <Form.Item name="title" className="mb-0 w-100">
                      <Input size="large" placeholder="Procurar video" />
                    </Form.Item>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row
              gutter={[
                { xs: 20, sm: 8, md: 16, lg: 60 },
                { xs: 40, sm: 8, md: 16, lg: 40 },
              ]}
              className="mt-60"
            >
              {videos.map((item, index) => {
                return (
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={8}
                    className="d-flex flex-column mb-20"
                    //onClick={() => handleOpenModalVideo(item)}
                  >
                    <div className="player-wrapper">
                      <ReactPlayer className="react-player" controls volume={1} url={item?.link} />
                    </div>
                    <p className="blue f-18 mb-10 mt-10">{item?.title}</p>
                    {item?.description && <p className="f-16 normal ellipsis_list_description">{item?.description}</p>}
                  </Col>
                );
              })}
            </Row>
          </Col>
        )}
      </Row>
    </Spin>
  );
}

export default TagFilter;
