import React, { useEffect, useState } from "react";
import { Button, Form, Input, InputNumber, Modal } from "antd";
import axios from "axios";

import endpoints from "../../../services/endpoints";

function Update({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      form.setFieldsValue(data);
    }
  }, [open]);

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios
      .post(endpoints.calculators.update, {
        data: values,
      })
      .then((res) => {
        setIsButtonLoading(false);
        form.resetFields();
        close();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function handleClose() {
    form.resetFields();
    close();
  }

  return (
    <Modal
      className="update-calculator p-20"
      key={`update-calculator-${data?.id}`}
      title="Editar calculadora"
      width={800}
      onCancel={handleClose}
      open={open}
      footer={[
        <Button size="large" disabled={isButtonLoading} onClick={handleClose}>
          Cancelar
        </Button>,
        <Button size="large" loading={isButtonLoading} type="primary" onClick={form.submit}>
          Editar
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input size="large" placeholder="Nome" />
        </Form.Item>
        <Form.Item
          label="Slug"
          name="slug"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input size="large" placeholder="Slug" />
        </Form.Item>
        <Form.Item
          label="Posição"
          name="position"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <InputNumber className="w-100" size="large" placeholder="0" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default Update;
