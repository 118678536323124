import React, { useState, useEffect } from "react";
import { Col, Row, Button, Empty, Pagination, Tooltip, Form, Input } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";

import endpoints from "../../../services/endpoints";
import Create from "../../../components/dashboard/calculator/Create";
import Update from "../../../components/dashboard/calculator/Update";
import Delete from "../../../components/dashboard/calculator/Delete";

import "../../../assets/styles/admin.css";

const DashboardTags = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFileteredData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(8);

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    axios
      .get(endpoints.calculators.read)
      .then((res) => {
        setData(res.data);
        setFileteredData(res.data);
      })
      .catch((error) => console.error(error));
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData(null);
    setIsOpenDelete(false);
    handleGetData();
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  function handleSearch(_, allValues) {
    let searchText = allValues.search;

    if (searchText.length > 0) {
      let aux = data.filter((item) => {
        return item.name.toLowerCase().includes(searchText) || item.slug.toLowerCase().includes(searchText);
      });

      setFileteredData(aux);
    } else {
      setFileteredData(data);
    }
  }

  return (
    <Row>
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Col span={24}>
        <Row className="title-admin-page d-flex jc-sb ai-center">
          <Col span={12}>
            <h2 className="m-0">Calculadoras</h2>
          </Col>
          <Col span={12}>
            <Row className="d-flex jc-end">
              <Form form={form} onValuesChange={handleSearch}>
                <Form.Item name="search" className="mr-10">
                  <Input size="large" placeholder="Procurar..." />
                </Form.Item>
              </Form>
              <Button size="large" onClick={handleOpenCreate}>
                Adicionar
              </Button>
            </Row>
          </Col>
        </Row>
        <Row gutter={[0, 24]}>
          {filteredData?.slice(minValue, maxValue).map((item) => {
            return (
              <Col span={24} key={item.id} className="p-0">
                <Row gutter={[24]} className="dashboard-article-item m-0">
                  <Col span={1} className="d-flex jc-center ai-center">
                    <p className="f-24">{item.position}</p>
                  </Col>
                  <Col span={22}>
                    <Row gutter={[24]}>
                      <Col span={12}>
                        <p className="f-11 mb-5">Nome</p>
                        <p className="ellipsisThree bold">{item.name}</p>
                        <p className="f-11 mt-20 mb-5">Slug</p>
                        <p className="f-12">{item.slug}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={1} className="d-flex flex-column jc-center ai-center">
                    <Tooltip title="Editar">
                      <EditOutlined key="edit" className="f-20 mb-20" onClick={() => handleOpenUpdate(item)} />
                    </Tooltip>
                    <Tooltip title="Apagar">
                      <DeleteOutlined key="delete" className="f-20 mb-20" onClick={() => handleOpenDelete(item)} />
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
            );
          })}

          <Row className="ml-0 mr-0" align="center">
            <Pagination showSizeChanger={false} onChange={handleChangePage} pageSize={itemsPerPage} defaultCurrent={1} current={currentPage} total={data.length} />
          </Row>
          {data.length === 0 && (
            <div style={{ width: "100%", display: "block", margin: "0 auto" }}>
              <Empty description="Não foram encontrados banners" image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default DashboardTags;
