import React, { useState, useEffect } from "react";
import { Col, Row, Button, message, Table, Input, Form, Divider, Spin } from "antd";
import axios from "axios";
import {
  EditOutlined,
  DeleteOutlined,
  SendOutlined,
  ReloadOutlined,
  AndroidOutlined,
  AppleOutlined,
  UserOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
  UsergroupAddOutlined,
  TeamOutlined,
  NotificationOutlined,
  HomeOutlined,
  DesktopOutlined,
} from "@ant-design/icons";

import endpoints from "../../../services/endpoints";

import amarin from "../../../assets/images/event/amarin.svg";
import az from "../../../assets/images/event/az.svg";
import azevedos from "../../../assets/images/event/azevedos.svg";
import bayer from "../../../assets/images/event/bayer.svg";
import bial from "../../../assets/images/event/bial.svg";
import boe from "../../../assets/images/event/boe.svg";
import dai from "../../../assets/images/event/daiichi.svg";
import ferrer from "../../../assets/images/event/ferrer.svg";
import medinfar from "../../../assets/images/event/medinfar.svg";
import menarin from "../../../assets/images/event/menarin.svg";
import pfizer from "../../../assets/images/event/pfizer.svg";
import servier from "../../../assets/images/event/servier.svg";
import tecnimede from "../../../assets/images/event/tecnimede.svg";
import unilabs from "../../../assets/images/event/unilabs.svg";
import viatris from "../../../assets/images/event/viatris.svg";

import "../../../assets/styles/admin.css";

const EventDashboard = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.events.read)
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }
  return (
    <Spin spinning={isLoading}>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={12} className="d-flex jc-start ai-center">
                <p className="mt-0 mb-0 blue f-20 bold">Participantes</p>
              </Col>
            </Row>
            <Divider className="mb-0 mt-0" />
            <Row className="p-20">
              <Col span={24} className="d-flex flex-column jc-center ai-center">
                <UserOutlined className="f-30 blue" />
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={8}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={12} className="d-flex jc-start ai-center">
                <p className="mt-0 mb-0 blue f-20 bold">Virtuais</p>
              </Col>
            </Row>
            <Divider className="mb-0 mt-0" />
            <Row className="p-20">
              <Col span={24} className="d-flex flex-column jc-center ai-center">
                <DesktopOutlined className="f-30 blue" />
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.type === "Virtual").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={8}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={12} className="d-flex jc-start ai-center">
                <p className="mt-0 mb-0 blue f-20 bold">Presenciais</p>
              </Col>
            </Row>
            <Divider className="mb-0 mt-0" />
            <Row className="p-20">
              <Col span={24} className="d-flex flex-column jc-center ai-center">
                <HomeOutlined className="f-30 blue" />
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.type === "Presencial").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={6}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={12} className="d-flex jc-start ai-center">
                <p className="mt-0 mb-0 blue f-20 bold">Staff</p>
              </Col>
            </Row>
            <Divider className="mb-0 mt-0" />
            <Row className="p-20">
              <Col span={24} className="d-flex flex-column jc-center ai-center">
                <UserOutlined className="f-30 blue" />
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.profile === "STAFF").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={6}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={12} className="d-flex jc-start ai-center">
                <p className="mt-0 mb-0 blue f-20 bold">Palestrantes</p>
              </Col>
            </Row>
            <Divider className="mb-0 mt-0" />
            <Row className="p-20">
              <Col span={24} className="d-flex flex-column jc-center ai-center">
                <UserOutlined className="f-30 blue" />
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.profile === "PALESTRANTE").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={6}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={12} className="d-flex jc-start ai-center">
                <p className="mt-0 mb-0 blue f-20 bold">Congressistas</p>
              </Col>
            </Row>
            <Divider className="mb-0 mt-0" />
            <Row className="p-20">
              <Col span={24} className="d-flex flex-column jc-center ai-center">
                <TeamOutlined className="f-30 blue" />
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.profile === "CONGRESSISTA").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={6}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={12} className="d-flex jc-start ai-center">
                <p className="mt-0 mb-0 blue f-20 bold">Moderadores</p>
              </Col>
            </Row>
            <Divider className="mb-0 mt-0" />
            <Row className="p-20">
              <Col span={24} className="d-flex flex-column jc-center ai-center">
                <UserOutlined className="f-30 blue" />
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.profile === "MODERADOR").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Divider className="mt-10 mb-10" />

        <Col span={4}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={10} className="d-flex jc-center ai-center">
                <img src={amarin} className="w-100" />
              </Col>
              <Col span={14} className="d-flex flex-column jc-center ai-end">
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.invited_from === "amarin").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={4}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={10} className="d-flex jc-center ai-center">
                <img src={az} className="w-100" />
              </Col>
              <Col span={14} className="d-flex flex-column jc-center ai-end">
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.invited_from === "az").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={4}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={10} className="d-flex jc-center ai-center">
                <img src={azevedos} className="w-100" />
              </Col>
              <Col span={14} className="d-flex flex-column jc-center ai-end">
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.invited_from === "azevedos").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={4}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={10} className="d-flex jc-center ai-center">
                <img src={bayer} className="w-100 maxh-50" />
              </Col>
              <Col span={14} className="d-flex flex-column jc-center ai-end">
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.invited_from === "bayer").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={4}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={10} className="d-flex jc-center ai-center">
                <img src={bial} className="w-100 maxh-50" />
              </Col>
              <Col span={14} className="d-flex flex-column jc-center ai-end">
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.invited_from === "bial").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={4}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={10} className="d-flex jc-center ai-center">
                <img src={boe} className="w-100 maxh-50" />
              </Col>
              <Col span={14} className="d-flex flex-column jc-center ai-end">
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.invited_from === "boe").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={4}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={10} className="d-flex jc-center ai-center">
                <img src={dai} className="w-100 maxh-50" />
              </Col>
              <Col span={14} className="d-flex flex-column jc-center ai-end">
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.invited_from === "dai").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={4}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={10} className="d-flex jc-center ai-center">
                <img src={ferrer} className="w-100 maxh-50" />
              </Col>
              <Col span={14} className="d-flex flex-column jc-center ai-end">
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.invited_from === "ferrer").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={4}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={10} className="d-flex jc-center ai-center">
                <img src={medinfar} className="w-100 maxh-50" />
              </Col>
              <Col span={14} className="d-flex flex-column jc-center ai-end">
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.invited_from === "medinfar").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={4}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={10} className="d-flex jc-center ai-center">
                <img src={medinfar} className="w-100 maxh-50" />
              </Col>
              <Col span={14} className="d-flex flex-column jc-center ai-end">
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.invited_from === "medinfar").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={4}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={10} className="d-flex jc-center ai-center">
                <img src={menarin} className="w-100 maxh-50" />
              </Col>
              <Col span={14} className="d-flex flex-column jc-center ai-end">
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.invited_from === "menarini").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={4}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={10} className="d-flex jc-center ai-center">
                <img src={pfizer} className="w-100 maxh-50" />
              </Col>
              <Col span={14} className="d-flex flex-column jc-center ai-end">
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.invited_from === "pfizer").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={4}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={10} className="d-flex jc-center ai-center">
                <img src={servier} className="w-100 maxh-50" />
              </Col>
              <Col span={14} className="d-flex flex-column jc-center ai-end">
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.invited_from === "servier").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={4}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={10} className="d-flex jc-center ai-center">
                <img src={tecnimede} className="w-100 maxh-50" />
              </Col>
              <Col span={14} className="d-flex flex-column jc-center ai-end">
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.invited_from === "tecnimede").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={4}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={10} className="d-flex jc-center ai-center">
                <img src={unilabs} className="w-100 maxh-50" />
              </Col>
              <Col span={14} className="d-flex flex-column jc-center ai-end">
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.invited_from === "unilabs").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={4}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={10} className="d-flex jc-center ai-center">
                <img src={viatris} className="w-100 maxh-50" />
              </Col>
              <Col span={14} className="d-flex flex-column jc-center ai-end">
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.invited_from === "viatris").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={4}>
          <div style={{ borderRadius: 8, backgroundColor: "#e0e0e0" }}>
            <Row className="p-20">
              <Col span={10} className="d-flex jc-center ai-center">
                <p>ND</p>
              </Col>
              <Col span={14} className="d-flex flex-column jc-center ai-end">
                <p className="f-30 bold text-center mb-0 mt-10">{data.length ? data.filter((item) => item.invited_from === "nd").length : 0}</p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default EventDashboard;
