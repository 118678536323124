import React, { useState } from "react";
import { Button, Col, Form, Input, Row, Tooltip } from "antd";
import { Helmet } from "react-helmet";

import CalculatorBanner from "../../../components/website/CalculatorBanner/CalculatorBanner";
import { CalculatorLinks } from "../../../components/website/CalculatorLinks/CalculatorLinks";
import { CalculatorResult } from "../../../components/website/CalculatorResult/CalculatorResult";
import { CalculatorContent } from "../../../components/website/CalculatorContent/CalculatorContent";

import "../../../assets/styles/calculator.css";

/* CALCULADORA CÁLCULO DO VALOR DE LDL */
const CalculatorLDLPage = () => {
  const [resultMessageLDL, setResultMessageLDL] = useState("");
  const [resultMessageHDL, setResultMessageHDL] = useState("");
  const [minValues] = useState({ total: 0, hdl: 0, tgc: 0 });
  const [maxValues] = useState({ total: 500, hdl: 500, tgc: 500 });
  const [isResultLoading, setIsResultLoading] = useState(false);
  const [form] = Form.useForm();

  const handleValuesChange = () => {
    let allValues = form.getFieldsValue();
    let columns = Object.keys(allValues);

    let nullItems = 0;

    for (let i = 0; i < columns.length; i++) {
      if (typeof allValues[columns[i]] === "string") {
        allValues[columns[i]] = Number(allValues[columns[i]]);
      }

      if (allValues[columns[i]] === null || allValues[columns[i]] === undefined) {
        nullItems++;
      }
    }

    if (nullItems === 0) {
      let totalPointsHDL = 0;
      let totalPointsNONHDL = 0;
      totalPointsHDL = allValues.total - allValues.hdl - allValues.tgc / 5;
      totalPointsNONHDL = allValues.total - allValues.hdl;
      totalPointsHDL = parseFloat(totalPointsHDL).toFixed(0);
      if (!isNaN(totalPointsHDL)) {
        setResultMessageLDL(totalPointsHDL);
        setIsResultLoading(false);
      }
      if (!isNaN(totalPointsNONHDL)) {
        setResultMessageHDL(totalPointsNONHDL);
      }
    }
  };

  function handleIncrement(key) {
    let value = form.getFieldValue(key);
    if (typeof value === "string") {
      value = Number(value);
    }

    if (value >= 0) {
      if (value >= minValues[key] && value < maxValues[key]) {
        value++;
      } else if (value < minValues[key]) {
        value = minValues[key];
      } else {
        value = maxValues[key];
      }

      form.setFieldValue([key], Number(value));
      form.validateFields([key]);
    } else {
      form.setFieldValue([key], minValues[key]);
      form.validateFields([key]);
    }
  }

  function handleDecrement(key) {
    let value = form.getFieldValue(key);
    if (typeof value === "string") {
      value = Number(value);
    }

    if (value >= 0) {
      if (value > minValues[key] && value <= maxValues[key]) {
        value--;
      } else if (value <= minValues[key]) {
        value = minValues[key];
      } else {
        value = maxValues[key];
      }

      form.setFieldValue([key], Number(value));
      form.validateFields([key]);
    } else {
      form.setFieldValue([key], minValues[key]);
      form.validateFields([key]);
    }
  }

  function handleValidate(rule, value) {
    let key = rule.field;
    let keysOfNumber = Object.keys(minValues);
    if (keysOfNumber.includes(key)) {
      if (Number(value) < minValues[rule.field]) {
        return Promise.reject(<Tooltip color="red" placement="bottom" open={true} title={`Este valor não pode ser menor que ${minValues[rule.field]}`}></Tooltip>); // The validator should always return a promise on both success and error
      } else if (Number(value) > maxValues[rule.field]) {
        return Promise.reject(<Tooltip color="red" placement="bottom" open={true} title={`Este valor não pode ser maior que ${maxValues[rule.field]}`}></Tooltip>); // The validator should always return a promise on both success and error
      } else {
        return Promise.resolve(); // The validator should always return a promise on both success and error
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Calculadora CÁLCULO DO VALOR DE LDL E COL | Formação Científica em Medicina Cardiovascular</title>
        <meta
          name="description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta
          name="keywords"
          content="calculadoras área cardiovascular, Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Calculadora CÁLCULO DO VALOR DE LDL E COL | Formação Científica em Medicina Cardiovascular" />
        <meta
          property="og:description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
      </Helmet>
      <CalculatorBanner title="CÁLCULO DO VALOR DE LDL E COL" />

      <CalculatorContent>
        <Form form={form} onFieldsChange={handleValuesChange} className="form_calculator">
          {/* Row #1 */}
          <Row
            gutter={[
              { xs: 20, sm: 8, md: 16, lg: 60 },
              { xs: 40, sm: 8, md: 16, lg: 60 },
            ]}
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Colesterol Total (mg/dL)</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("total")} className="calculator_button mr-10">
                  -
                </Button>
                <Form.Item name={"total"} className="w-100 mb-0" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("total")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Colesterol HDL (mg/dL)</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("hdl")} className="calculator_button mr-10">
                  -
                </Button>
                <Form.Item name={"hdl"} className="w-100 mb-0" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("hdl")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
            </Col>

            {/* Row #2 */}

            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Triglicerídeos (mg/dL)</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("tgc")} className="calculator_button mr-10">
                  -
                </Button>
                <Form.Item name={"tgc"} className="w-100 mb-0" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("tgc")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </CalculatorContent>
      {resultMessageLDL && (
        <CalculatorResult isLoading={isResultLoading}>
          <Row
            gutter={[
              { xs: 20, sm: 8, md: 16, lg: 30 },
              { xs: 0, sm: 8, md: 16, lg: 30 },
            ]}
            className="h-100 ai-center jc-start"
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <p className="f-16 text-uppercase mb-10">Colesterol LDL:</p>
              <h3 className="f-30 bold">{resultMessageLDL} mg/dL</h3>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p className="f-16 text-uppercase mb-10">Colesterol HDL:</p>
              <h3 className="f-30 bold">{resultMessageLDL} mg/dL</h3>
            </Col>
          </Row>
        </CalculatorResult>
      )}

      <CalculatorLinks />
    </>
  );
};
export default CalculatorLDLPage;
