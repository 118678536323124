import React, { useState } from "react";
import { Button, Form, Input, Modal, message } from "antd";
import axios from "axios";

import endpoints from "../../../services/endpoints";

import "./ModalDeleteAccount.css";

function ModalDeleteAccount({ user, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios
      .post(endpoints.user.delete, {
        data: { id: user.id, password: values.password },
      })
      .then((res) => {
        if (res.data.user_deleted) {
          close(res.data.user_deleted);
        } else {
          message.error({ content: res.data.message });
        }
        setIsButtonLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
      });
  }

  return (
    <Modal className="modal-delete-account" key={`modal-delete-account`} onCancel={() => close(false)} open={open} footer={[]}>
      <div className="d-flex flex-column p-20">
        <p className="f-30">Tem a certeza que quer apagar a sua conta?</p>
        <p className="mt-20">Para apagar a sua conta e por questões de segurança terá de preencher o seguinte formulário com a sua password:</p>
        <Form form={form} onFinish={handleSubmit} className="mt-20">
          <Form.Item name="password">
            <Input.Password size="large" placeholder="Insira a sua password aqui" />
          </Form.Item>
        </Form>
        <div className="d-flex jc-end ai-center">
          <Button size="large" className="mr-10" onClick={() => close(false)}>
            Cancelar
          </Button>
          <Button type="primary" danger size="large" className="ml-10" onClick={form.submit}>
            Apagar conta
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalDeleteAccount;
