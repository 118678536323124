import React, { useState, useEffect } from "react";
import { Col, Row, Button, Tabs } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import axios from "axios";

import Chat from "./Chat";
import endpoints from "../../../services/endpoints";

import "../../../assets/styles/admin.css";

const DashboardSettings = () => {
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.settings.read)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  return (
    <Row>
      <Col span={24}>
        <Row className="title-admin-page d-flex jc-sb ai-center">
          <Col span={6}>
            <h2 className="m-0">Definições</h2>
          </Col>
          <Col span={18}>
            <div className="d-flex jc-end ai-center">
              <Button className="mr-10" size="large" onClick={handleGetData}>
                <ReloadOutlined />
              </Button>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Tabs
              defaultActiveKey="chat"
              type="card"
              items={[
                {
                  label: `Chat`,
                  key: "chat",
                  children: <Chat data={data} handleGetData={handleGetData} />,
                },
              ]}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DashboardSettings;
