import React, { useEffect, useState } from "react";
import slugify from "react-slugify";
import { Col, Row, Form, Input, Tabs } from "antd";
import { useLocation, useSearchParams } from "react-router-dom";

import VideocastTab from "./VideocastTab";
import GuidesTab from "./GuidesTab";
import PodcastsTab from "./PodcastsTab";
import CalculatorsTab from "./CalculatorsTab";

import PodcastsIcon from "../../../assets/images/podcasts.svg";
import VideocastIcon from "../../../assets/images/videocasts.svg";
import GuiasIcon from "../../../assets/images/guias-praticos.svg";
import CalculatorsIcon from "../../../assets/images/calculadoras.svg";
import SearchIcon from "../../../assets/images/search-icon.svg";

import "./ListVideocasts.css";

const ListVideocasts = ({ data, dataPodcasts, dataPraticalGuides }) => {
  const [videos, setVideos] = useState({});
  const [videocasts, setVideocasts] = useState([]);
  const [guides, setGuides] = useState([]);
  const [calculators, setCalculators] = useState([]);
  const [praticalGuides, setPraticalGuides] = useState([]);
  const [podcasts, setPodcasts] = useState([]);
  const [filteredVideocasts, setFilteredVideocasts] = useState([]);
  const [filteredGuides, setFilteredGuides] = useState([]);
  const [filteredPodcasts, setFilteredPodcasts] = useState([]);
  const [filteredCalculators, setFilteredCalculators] = useState([]);
  const [inputFilter, setInputFilter] = useState("");
  const [selectFilter, setSelectFilter] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState({});
  const [isOpenModalVideo, setIsOpenModalVideo] = useState(false);
  const [tabPosition, setTabPosition] = useState("left");
  const [activeKey, setActiveKey] = useState("videocasts");

  const [searchParams, setSearchParams] = useSearchParams();

  const [form] = Form.useForm();

  const location = useLocation();

  useEffect(() => {
    handlePrepareData(data, dataPodcasts);
    if (location.search) {
      let titleSearch = searchParams.get("titulo");
      let descriptionSearch = searchParams.get("descricao");
      let tab = searchParams.get("tab");
      if (tab) {
        setActiveKey(tab);
      }
      let findVideo = data.filter((item) => slugify(item.title) === titleSearch && slugify(item.description) === descriptionSearch);

      if (findVideo.length > 0) {
        handleOpenModalVideo(findVideo[0]);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [data, dataPodcasts, dataPraticalGuides]);

  // Handler to call on window resize
  function handleResize() {
    // Set window width/height to state
    if (window.innerWidth < 768) {
      setTabPosition("top");
    } else {
      setTabPosition("left");
    }
  }

  function handlePrepareData(v, p) {
    let auxVideocasts = [];
    let auxCalculators = [];
    let auxGuides = [];
    auxGuides = v.filter((item) => item.type === "guides");
    auxCalculators = v.filter((item) => item.type === "calculators");
    auxVideocasts = v.filter((item) => item.type === "videocasts" || item.type === "videocasts_scientific_update");

    setVideos(v);
    setVideocasts(auxVideocasts);
    setGuides(auxGuides);
    setPodcasts(p);
    setCalculators(auxCalculators);
    setFilteredVideocasts(auxVideocasts);
    setFilteredGuides(auxGuides);
    setFilteredPodcasts(p);
    setFilteredCalculators(auxCalculators);
    setPraticalGuides(dataPraticalGuides);
  }

  function handleOpenModalVideo(item) {
    setSelectedVideo(item);
    setIsOpenModalVideo(true);
  }

  function handleCloseModal() {
    setIsOpenModalVideo(false);
  }

  function handleFilter(e) {
    let string = e.title.toLowerCase();
    let filterVideocasts = videocasts.filter((item) => item.title.toLowerCase().includes(string.toLowerCase()) || item.description?.toLowerCase().includes(string.toLowerCase()));
    let filterGuides = guides.filter((item) => item.title.toLowerCase().includes(string.toLowerCase()) || item.description?.toLowerCase().includes(string.toLowerCase()));
    let filterPodcasts = podcasts.filter((item) => item.title.toLowerCase().includes(string.toLowerCase()) || item.description?.toLowerCase().includes(string.toLowerCase()));
    let filterCalculators = calculators.filter((item) => item.title.toLowerCase().includes(string.toLowerCase()) || item.description?.toLowerCase().includes(string.toLowerCase()));

    setFilteredVideocasts(filterVideocasts);
    setFilteredGuides(filterGuides);
    setFilteredPodcasts(filterPodcasts);
    setFilteredCalculators(filterCalculators);
  }

  return (
    <Row id="section-list-videos" className="container mt-60">
      <Col span={24} className="d-flex flex-column jc-center ai-center">
        <Tabs
          id="tabs_videos"
          className="tabs_videos"
          tabPosition={tabPosition}
          tabBarExtraContent={
            tabPosition === "left" ? (
              <Form form={form} onValuesChange={handleFilter} className="form_videocasts">
                <Row className="d-flex flex-nowrap mb-20">
                  <div className="d-flex jc-center ai-center">
                    <img src={SearchIcon} width={"25px"} />
                  </div>
                  <Form.Item name="title" className="mb-0 w-100">
                    <Input size="large" allowClear value={inputFilter} placeholder="Procurar video..." className="ml-5" />
                  </Form.Item>
                </Row>
              </Form>
            ) : null
          }
          activeKey={activeKey}
          onChange={(e) => setActiveKey(e)}
          items={[
            {
              label: (
                <div className="tabs_videos_item">
                  <img className="mr-6" src={VideocastIcon} />
                  <p className="m-0">Videocasts</p>
                </div>
              ),
              key: "videocasts",
              children: <VideocastTab data={filteredVideocasts} handleOpenModalVideo={handleOpenModalVideo} />,
            },
            {
              label: (
                <div className="tabs_videos_item">
                  <img className="mr-6" src={GuiasIcon} />
                  <p className="m-0">Guias práticos</p>
                </div>
              ),
              key: "guides",
              children: <GuidesTab data={filteredGuides} guides={praticalGuides} handleOpenModalVideo={handleOpenModalVideo} />,
            },
            {
              label: (
                <div className="tabs_videos_item">
                  <img className="mr-6" src={PodcastsIcon} />
                  <p className="m-0">Podcasts</p>
                </div>
              ),
              key: "podcasts",
              children: <PodcastsTab data={filteredPodcasts} handleOpenModalVideo={handleOpenModalVideo} />,
            },
            {
              label: (
                <div className="tabs_videos_item">
                  <img className="mr-6" src={CalculatorsIcon} />
                  <p className="m-0">Calculadoras</p>
                </div>
              ),
              key: "calculators",
              children: <CalculatorsTab data={filteredCalculators} handleOpenModalVideo={handleOpenModalVideo} />,
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default ListVideocasts;
