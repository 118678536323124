import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import "../../../assets/styles/banner.css";

import endpoints from "../../../services/endpoints";
import config from "../../../services/config";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./BannerHome.css";
import { Pagination } from "swiper/modules";

const BannerHome = () => {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    handleGetBanners();
  }, []);

  function handleGetBanners() {
    axios
      .get(endpoints.banners.read)
      .then((res) => {
        console.log(res);
        setBanners(
          res.data.sort((a, b) => {
            if (a.position < b.position) {
              return -1;
            } else if (a.position > b.position) {
              return 1;
            }
            // a must be equal to b
            return 0;
          })
        );
      })
      .catch((error) => console.error(error));
  }

  return (
    <>
      <Row className="banner_desktop_parent">
        <Col span={24} className="banner_home">
          <Swiper
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            id="banner_desktop"
          >
            {banners.map((item, index) => {
              return (
                <SwiperSlide className="slide_banner_desktop" key={`banner_home_${index}`}>
                  <Row
                    className="banner_principal"
                    style={{
                      backgroundImage: `linear-gradient(90deg, rgba(255,255,255,1) 100%, rgba(255,255,255,0) 100%)`,
                    }}
                  >
                    <Row className="background_img_row">
                      <Col span={12}></Col>
                      <Col
                        className="background_img_col"
                        span={12}
                        style={{
                          backgroundImage: `url(${config.server_ip}/assets/media/${item.image_desktop})`,
                        }}
                      ></Col>
                    </Row>
                    <Col span={24} className="container">
                      <Row gutter={[24]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <h3 className="blue f-45">{item.title}</h3>
                          {item.subtitle && <div className="mt-20 blue banner_description" dangerouslySetInnerHTML={{ __html: item.subtitle }}></div>}
                          <Link to={item.button_link} target={item.button_link?.includes("http") ? "_blank" : "_self"}>
                            <Button
                              className="mt-30"
                              type="primary"
                              size="large"
                              style={{
                                backgroundColor: item.button_back_color,
                                color: item.button_text_color,
                              }}
                            >
                              {item.button_text}
                            </Button>
                          </Link>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}></Col>
                      </Row>
                    </Col>
                  </Row>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Col>
      </Row>
      <Row className="banner_mobile">
        <Swiper pagination={true} modules={[Pagination]} className="swiper_mobile">
          {banners.map((item, index) => {
            return (
              <SwiperSlide
                key={`banner_mobile_${index}`}
                id="banner_mobile"
                style={{
                  backgroundImage: `url(${config.server_ip}/assets/media/${item.image_mobile})`,
                  backgroundPosition: "center bottom",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="d-flex flex-column jc-center ai-center pl-30 pr-30">
                  <h3 className="white">{item.title}</h3>
                  <div className="mt-20 white" dangerouslySetInnerHTML={{ __html: item.subtitle }}></div>
                  <Link to={item.button_link} target={item.button_link.includes("http") ? "_blank" : "_self"}>
                    <Button
                      className="mt-30"
                      type="primary"
                      size="large"
                      style={{
                        backgroundColor: item.button_back_color,
                        color: item.button_text_color,
                      }}
                    >
                      {item.button_text}
                    </Button>
                  </Link>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Row>
    </>
  );
};

export default BannerHome;
