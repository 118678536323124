import React, { useEffect, useState } from "react";
import { Outlet, useLocation, Link, useNavigate } from "react-router-dom";
import { Layout, Menu, message } from "antd";
import { UserOutlined, DashboardOutlined, FileOutlined, SettingOutlined, TeamOutlined, MessageOutlined, FileImageOutlined, CameraOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import { VscDeviceCameraVideo } from "react-icons/vsc";
import { CiCalculator2, CiMicrophoneOn, CiHashtag } from "react-icons/ci";
import { PiSlideshow } from "react-icons/pi";
import { RiArticleLine } from "react-icons/ri";
import { TbReportMedical } from "react-icons/tb";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { FaRegFileAlt, FaRegNewspaper } from "react-icons/fa";
import { MdEvent } from "react-icons/md";
import { LuBrain } from "react-icons/lu";

import "./Admin.css";

const { Header, Sider, Content } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem("Artigos", "admin/articles", <RiArticleLine />),
  getItem("Banners", "admin/banners", <PiSlideshow />),
  getItem("Calculadoras", "admin/calculadoras", <CiCalculator2 />),
  getItem("Multimédia", "admin/media", <CameraOutlined />),
  getItem("Podcasts", "admin/podcasts", <CiMicrophoneOn />),
  getItem("Tags", "admin/tags", <CiHashtag />),
  getItem("Áreas médicas", "admin/medicalAreas", <TbReportMedical />),
  getItem("Videos", "admin/videos", <VscDeviceCameraVideo />),
  getItem("Cursos", "admin/cursos", <HiOutlineAcademicCap />),
  getItem("Guias", "admin/guides", <FaRegFileAlt />),
  getItem("Casos clínicos", "admin/clinical-cases", <LuBrain />),
  getItem("Newsletters", "admin/newsletters", <FaRegNewspaper />),
  getItem("Utilizadores", "admin/users", <TeamOutlined />),
  getItem("Evento", "g1", <MdEvent />, [
    getItem("Dashboard", "admin/event/dashboard", <DashboardOutlined />),
    getItem("Participantes", "admin/event/participants", <TeamOutlined />),
    getItem("Definições", "admin/event/settings", <SettingOutlined />),
  ]),
];

const Admin = ({ user, isLoggedIn, handleLogOut }) => {
  const location = useLocation();
  const navigate = useNavigate();
  let pathname = location.pathname.split("/");

  const [current, setCurrent] = useState(pathname[pathname.length - 1] === "" ? "admin/" : `admin/${pathname[pathname.length - 1]}`);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user) {
      handleVerifyUser();
    }
  }, [user]);

  useEffect(() => {
    if (location.pathname.includes("admin")) {
      document.body.classList.add("admin-dashboard");
    }
  }, [location]);

  function handleVerifyUser() {
    if (!user) {
      message.error({
        content: "Antes de entrar no backoffice precisa de fazer login",
      });
      return navigate(`/login?redirect=/admin`);
    } else {
      if (!user.is_admin) {
        message.error({
          content: "Não tem permissões para entrar no backoffice",
        });
        return navigate("/");
      } else {
        setIsLoading(false);
      }
    }
  }

  function handleClickMenu(e) {
    navigate(e.key);
  }

  return (
    <>
      <Helmet>
        <title>Cardio4All | Backoffice</title>
        <meta name="description" content="Cardio4All | Backoffice" />
      </Helmet>
      {isLoading ? (
        <div id="loader"></div>
      ) : (
        <Layout className="admin_layout">
          <Sider width={250}>
            <Link to="/">
              <div className="logo"></div>
            </Link>
            <Menu theme="dark" defaultSelectedKeys={[current]} mode="inline" items={items} onClick={handleClickMenu} />
          </Sider>
          <Layout className="site-layout">
            <Header>
              <div className="d-flex jc-end ai-center white">
                <UserOutlined className="mr-10" /> {user.name}
              </div>
            </Header>
            <Content className="admin_layout_content">
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      )}
    </>
  );
};
export default Admin;
