import React, { useState, useEffect } from "react";
import { Col, Row, Button, Table } from "antd";
import { EditOutlined, DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";

import Update from "../../../components/dashboard/newsletter/Update";
import Create from "../../../components/dashboard/newsletter/Create";
import Delete from "../../../components/dashboard/newsletter/Delete";
import endpoints from "../../../services/endpoints";
import config from "../../../services/config";

import "../../../assets/styles/admin.css";

const DashboardNewsletter = () => {
  const [newsletters, setNewsletters] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedNewsletter, setSelectedNewsletter] = useState([]);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const columns = [
    {
      title: "",
      dataIndex: "thumbnail",
    },
    {
      title: "Título",
      dataIndex: "title",
    },
    {
      title: "Data",
      dataIndex: "date",
    },
    {
      title: "Ficheiro",
      dataIndex: "file",
    },
    {
      title: "",
      dataIndex: "actions",
    },
  ];

  useEffect(() => {
    handleGetNewsletters();
  }, []);

  function handleGetNewsletters() {
    axios
      .get(endpoints.newsletters.read)
      .then((res) => {
        setNewsletters(res.data);
        handlePrepareData(res.data);
      })
      .catch((error) => console.error(error));
  }

  function handlePrepareData(data) {
    let aux = [];
    for (let i = 0; i < data.length; i++) {
      let auxObj = {
        thumbnail: <img src={`${config.server_ip}/assets/media/${data[i].thumbnail}`} className="maxw-200" />,
        title: data[i].title,
        date: dayjs(data[i].date).format("DD-MM-YYYY"),
        file: (
          <Button target="_blank" href={`${config.server_ip}/assets/media/${data[i].file}`}>
            <DownloadOutlined />
          </Button>
        ),
        actions: (
          <div className="d-flex jc-end">
            <Button className="ml-10" onClick={() => handleOpenUpdate(data[i])}>
              <EditOutlined />
            </Button>
            <Button className="ml-10" onClick={() => handleOpenDelete(data[i])}>
              <DeleteOutlined />
            </Button>
          </div>
        ),
      };

      aux.push(auxObj);
    }

    setTableData(aux);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetNewsletters();
  }

  function handleOpenUpdate(data) {
    setSelectedNewsletter(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetNewsletters();
  }

  function handleOpenDelete(data) {
    setSelectedNewsletter(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedNewsletter({});
    setIsOpenDelete(false);
    handleGetNewsletters();
  }

  return (
    <Row>
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update open={isOpenUpdate} close={handleCloseUpdate} data={selectedNewsletter} />
      <Delete open={isOpenDelete} close={handleCloseDelete} data={selectedNewsletter} />
      <Col span={24}>
        <Row className="title-admin-page d-flex jc-sb ai-center">
          <Col>
            <h2 className="m-0">Newsletters</h2>
          </Col>
          <Col>
            <Button size="large" onClick={handleOpenCreate}>
              Adicionar
            </Button>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Table columns={columns} dataSource={tableData} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DashboardNewsletter;
