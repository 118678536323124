import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Col, Row, Spin } from "antd";
import { Helmet } from "react-helmet";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";

import BgGuiasPraticos from "../../../assets/images/guias-praticosk-bg.png";
import CalculatorCTA from "../../../components/website/CalculatorCTA/CalculatorCTA";
import BannerPrincipal from "../../../components/website/BannerPrincipal/BannerPrincipal";

import endpoints from "../../../services/endpoints";

import "./PracticalGuideDetails.css";

const PracticalGuideDetails = () => {
  const [guide, setGuide] = useState({});
  const [modules, setModules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { slug } = useParams();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleGetGuide();
  }, []);

  function handleGetGuide() {
    axios
      .get(endpoints.guides.readBySlug, {
        params: { slug },
      })
      .then((res) => {
        let auxModules = res.data[1];
        let auxTopics = res.data[2];
        let newModules = [];

        for (let i = 0; i < auxModules.length; i++) {
          newModules.push({
            ...auxModules[i],
            topics: auxTopics.filter((item) => item.id_guide_module === auxModules[i].id),
          });
        }

        setGuide(res.data[0][0]);
        setModules(newModules);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Spin spinning={isLoading}>
      <Helmet>
        <title>{guide?.title ? guide.title : "Guia Prático"} | Cardio4All</title>
        <meta name="description" content="Guias Práticos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta
          name="keywords"
          content="Guias Práticos do Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta
          property="og:title"
          content="Guias Práticos do Cardio4all de a atualização científica e com as últimas guidelines de diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:description" content="Guias Práticos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta property="og:image" content={`${BgGuiasPraticos}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Row>
        <Col span={24}>
          <BannerPrincipal
            color={"rgb(240, 76, 75)"}
            backgroundImage={guide?.image}
            children={
              <>
                <p className="f-20" style={{ color: "#F8A6A5" }}>
                  Guia Prático
                </p>
                <p className="white f-26 bold mt-10 ellipsisThree">{guide?.title}</p>
              </>
            }
          />
          <Row className="container mt-20">
            <Link to="/guias-praticos" className="d-flex flex-column">
              <p style={{ color: "#85B8E3" }} className="f-16 bold">
                Voltar a guias
              </p>
              <span className="d-flex ai-center jc-center mt-10">
                <ArrowLeftOutlined style={{ color: "#85B8E3" }} />
              </span>
            </Link>
          </Row>
          <Row className="container modules_row">
            <Col span={24}>
              {modules.map((item, index) => (
                <Row
                  gutter={[24]}
                  className="module_row_item mb-20 ml-0 mr-0"
                  style={{
                    flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                  }}
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{
                      borderRight: windowWidth < 760 ? "none" : index % 2 === 0 ? "1px solid #9C9DA1" : "none",
                      borderLeft: windowWidth < 760 ? "none" : index % 2 === 0 ? "none" : "1px solid #9C9DA1",
                      borderBottom: windowWidth < 760 ? "1px solid #9C9DA1" : "none",
                    }}
                    className="d-flex flex-column jc-center p-20"
                  >
                    <p className="f-40 blue bold">{item.position}</p>
                    <p className="f-26 blue">{item.title}</p>
                  </Col>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16} style={{ padding: "20px" }}>
                    {item.topics.map((t, i) => (
                      <Link className="topic_line" to={`/guias-praticos/${slug}/${t.slug}`}>
                        <div className="d-flex jc-sb mt-10 mb-10">
                          <div>
                            <p className="blue">
                              {item.position}.{i + 1} {t.title}
                            </p>
                          </div>
                          <div className="button_topic">
                            <p className="white f-12 mr-10">ver tópico</p>
                            <span>
                              <ArrowRightOutlined style={{ color: "#85B8E3" }} />
                            </span>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
          <CalculatorCTA />
        </Col>
      </Row>
    </Spin>
  );
};

export default PracticalGuideDetails;
