import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Col, Row, Card, Button, Empty } from "antd";
import { Link } from "react-router-dom";
import bialImage from "../../../assets/images/event/bial.svg";

import VideocastsBialCTA from "../VideocastsBialCTA/VideocastsBialCTA";

import "./ListVideocasts.css";
import config from "../../../services/config";
import dayjs from "dayjs";

const { Meta } = Card;

const VideocastTab = ({ data, handleOpenModalVideo }) => {
  const [itemsToShow, setItemsToShow] = useState(10);
  const [videos, setVideos] = useState([]);
  const [scientificVideos, setScientificVideos] = useState({});

  useEffect(() => {
    if (data) {
      let auxScientific = data.filter((item) => item.type === "videocasts_scientific_update");
      let auxVideos = data.filter((item) => item.type === "videocasts");

      auxScientific.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
      let newObj = groupBy(auxScientific, "scientific_type");

      setVideos(auxVideos);
      setScientificVideos(newObj);
    }
  }, [data]);

  const handleLoadMore = () => {
    setItemsToShow(itemsToShow + 10);
  };

  function groupBy(arr, property) {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }

  function handleCalcDate(item) {
    const date1 = dayjs();
    const date2 = dayjs(item.date);
    let dayDiff = date1.diff(date2, "seconds");
    return dayDiff >= 0;
  }

  return (
    <>
      {Object.keys(scientificVideos).length > 0 && (
        <Row style={{ background: "#E1EDF8" }} className="p-20 mb-40">
          {Object.keys(scientificVideos).map((key, keyIndex) => (
            <Col span={24} className="container pt-0 pb-40">
              <p className="blue f-24 bold mt-0" dangerouslySetInnerHTML={{ __html: `5 mensagens para ${key}` }}></p>
              {keyIndex === 0 && <p className="f-12 blue mb-20">(5 mensagems práticas, em 5 minutos)</p>}
              <Row gutter={[24]}>
                {scientificVideos[key].map((item, index) => (
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="d-flex flex-column mb-20"
                    //onClick={() => handleOpenModalVideo(item)}
                  >
                    {!handleCalcDate(item) ? (
                      <div className={`${handleCalcDate(item) && "hover-cursor"} d-flex jc-center ai-center position-relative mt-20`}>
                        <p className="white f-24 bold" style={{ position: "absolute", zIndex: 2 }}>
                          Brevemente
                        </p>
                        <img
                          className="w-100"
                          src={`${config.server_ip}/assets/media/${item.image}`}
                          style={{
                            filter: handleCalcDate(item) ? "none" : "brightness(40%)",
                          }}
                        />
                      </div>
                    ) : (
                      <div className="player-wrapper">
                        <ReactPlayer className="react-player" controls volume={1} url={item?.link} />
                      </div>
                    )}

                    <p className="bold blue f-18 mb-10 mt-10">{item?.title}</p>
                    <p className="f-16 normal ellipsis_list_description">{item?.description}</p>
                    {item?.button_text && item?.button_link && (
                      <Row className="mt-10">
                        <Col span={24} align="middle">
                          <Link to={item.button_link} target="_blank">
                            <Button type="primary" size="large">
                              {item.button_text}
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    )}
                  </Col>
                ))}
              </Row>
            </Col>
          ))}
          <Col span={24} className="d-flex jc-end ai-center">
            <span className="blue f-14 mr-10">Com o apoio: </span>
            <img src={bialImage} className="maxh-30" />
          </Col>
        </Row>
      )}
      {videos.length > 0 ? (
        <Row
          gutter={[
            { xs: 40, sm: 8, md: 16, lg: 24 },
            { xs: 40, sm: 8, md: 16, lg: 24 },
          ]}
          className="mr-0 ml-0 d-flex"
        >
          {videos?.slice(0, itemsToShow + 1).map((item, index) => {
            return (
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                className="d-flex flex-column mb-20"
                //onClick={() => handleOpenModalVideo(item)}
              >
                <div className="player-wrapper">
                  <ReactPlayer className="react-player" controls volume={1} url={item?.link} />
                </div>
                <p className="bold blue f-18 mb-10 mt-10">{item?.title}</p>
                <p className="f-16 normal ellipsis_list_description">{item?.description}</p>
                {item?.button_text && item?.button_link && (
                  <Row className="mt-10">
                    <Col span={24} align="middle">
                      <Link to={item.button_link} target="_blank">
                        <Button type="primary" size="large">
                          {item.button_text}
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                )}
              </Col>
            );
          })}
          <VideocastsBialCTA />
          <Row className="d-flex jc-center ai-center mt-40 mb-40">
            {data && itemsToShow < data?.length && (
              <Button className="btn-ver-mais" onClick={handleLoadMore}>
                Explorar mais videos
              </Button>
            )}
          </Row>
        </Row>
      ) : (
        <Empty description="Não foram encontrados videos" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

export default VideocastTab;
