import React, { useState } from "react";
import { Col, Form, Row, Radio } from "antd";
import { Helmet } from "react-helmet";

import CalculatorBanner from "../../../components/website/CalculatorBanner/CalculatorBanner";
import { CalculatorLinks } from "../../../components/website/CalculatorLinks/CalculatorLinks";
import { CalculatorResult } from "../../../components/website/CalculatorResult/CalculatorResult";
import { CalculatorContent } from "../../../components/website/CalculatorContent/CalculatorContent";

import "../../../assets/styles/calculator.css";

/* CALCULADORA TESTE DE FAGERSTRÖM ADAPTADO */
const CalculatorFagerstromPage = () => {
  const [resultMessage, setResultMessage] = useState("");
  const [resultPoints, setResultPoints] = useState("");
  const [form] = Form.useForm();

  const handleValuesChange = (changedValues, allValues) => {
    let calculatorObject = { ...allValues };
    let nullItems = 0;
    let sumPoints = 0;

    for (let prop in calculatorObject) {
      if (calculatorObject[prop] !== null) {
        sumPoints += calculatorObject[prop];
      } else {
        nullItems++;
      }
    }

    setResultPoints(sumPoints.toString() + (sumPoints === 1 ? " ponto" : " pontos"));
    let tab_treatment = "";
    if (nullItems === 0) {
      if (sumPoints <= 3) {
        tab_treatment = "dependência REDUZIDA";
      } else if (sumPoints > 3 && sumPoints <= 6) {
        tab_treatment = "depedência MORDERADA";
      } else if (sumPoints > 6) {
        tab_treatment = "depedência ELEVADA";
      }
      setResultMessage(tab_treatment);
    }
  };

  return (
    <>
      <Helmet>
        <title>Calculadora TESTE DE FAGERSTRÖM ADAPTADO | Formação Científica em Medicina Cardiovascular</title>
        <meta
          name="description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta
          name="keywords"
          content="calculadoras área cardiovascular, Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Calculadora TESTE DE FAGERSTRÖM ADAPTADO | Formação Científica em Medicina Cardiovascular" />
        <meta
          property="og:description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
      </Helmet>
      <CalculatorBanner title="TESTE DE FAGERSTRÖM ADAPTADO" />
      <CalculatorContent className="mt-10">
        <Form form={form} className="form_calculator" onValuesChange={handleValuesChange}>
          {/* Row #1 */}
          <Row
            gutter={[
              { xs: 20, sm: 8, md: 16, lg: 60 },
              { xs: 40, sm: 8, md: 16, lg: 60 },
            ]}
            className="mb-40"
          >
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <p className="blue bold">Quanto tempo depois de acordar fuma o primeiro cigarro?</p>
              <Form.Item name={"primeiro_cigarro"} className="w-100 mt-10">
                <Radio.Group className="w-100">
                  <Row className="w-100">
                    <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex flex-column mr-10 w-100">
                      <Radio.Button value={3} className="w-100">
                        Nos primeiros 5 minutos
                      </Radio.Button>
                      <p className="f-14 mt-10 mb-10 text-right">3 pontos</p>
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex flex-column w-100">
                      <Radio.Button value={2} className="w-100">
                        Após 6 - 30 minutos
                      </Radio.Button>
                      <p className="f-14 mt-10 mb-10 text-right">2 pontos</p>
                    </Col>
                  </Row>
                  <Row className="w-100 mt-10">
                    <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex flex-column mr-10 w-100">
                      <Radio.Button value={1} className="w-100">
                        Após 31 - 60 minutos
                      </Radio.Button>
                      <p className="f-14 mt-10 mb-10 text-right">1 ponto</p>
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex flex-column w-100">
                      <Radio.Button value={0} className="w-100">
                        {"Após > 60 minutos"}
                      </Radio.Button>
                      <p className="f-14 mt-10 mb-10 text-right">0 pontos</p>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <p className="blue bold">Quantos cigarros fuma por dia?</p>
              <Form.Item name={"quantidade_cigarro"} className="w-100 mt-10">
                <Radio.Group className="w-100">
                  <Row className="w-100">
                    <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex flex-column mr-10 w-100">
                      <Radio.Button value={0} className="w-100">
                        {"< = 10"}
                      </Radio.Button>
                      <p className="f-14 mt-10 text-right">0 pontos</p>
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex flex-column mr-10 w-100">
                      <Radio.Button value={1} className="w-100">
                        11 - 20
                      </Radio.Button>
                      <p className="f-14 mt-10 text-right">1 ponto</p>
                    </Col>
                  </Row>
                  <Row className="w-100 mt-10">
                    <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex flex-column mr-10 w-100">
                      <Radio.Button value={2} className="w-100">
                        21 - 30
                      </Radio.Button>
                      <p className="f-14 mt-10 text-right mb-10">2 pontos</p>
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex flex-column mr-10 w-100">
                      <Radio.Button value={3} className="w-100">
                        {">= 31"}
                      </Radio.Button>
                      <p className="f-14 mt-10 text-right mb-10">3 pontos</p>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Col>

            {/* Row #2 */}

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p className="blue bold">É difícil para si não fumar em espaços onde é proibido fumar (cinemas, viagens de avião, etc.)?</p>
              <Form.Item name={"proibido_fumar"} className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Row className="w-100">
                    <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex flex-column mr-10 w-100">
                      <Radio.Button value={1} className="w-100 mb-10">
                        Sim
                      </Radio.Button>
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex flex-column mr-10 w-100">
                      <Radio.Button value={0} className="w-100 mb-10">
                        Não
                      </Radio.Button>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10 text-center">Sim = 1 ponto | Não = 0</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p className="blue bold">Fuma mais frequentemente nas primeiras horas após acordar do que no resto do dia?</p>
              <Form.Item name={"fumar_mais_ao_acordar"} className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Row className="w-100">
                    <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex flex-column mr-10 w-100">
                      <Radio.Button value={1} className="w-100 mb-10">
                        Sim
                      </Radio.Button>
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex flex-column mr-10 w-100">
                      <Radio.Button value={0} className="w-100 mb-10">
                        Não
                      </Radio.Button>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10 text-center">Sim = 1 ponto | Não = 0</p>
            </Col>

            {/* Row #3 */}

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p className="blue bold">O primeiro cigarro da manhã é o que lhe traz mais satisfação?</p>
              <Form.Item name={"fumar_mais_frequentemente"} className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Row className="w-100">
                    <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex flex-column mr-10 w-100">
                      <Radio.Button value={1} className="w-100 mb-10">
                        Sim
                      </Radio.Button>
                      <p className="f-14 mt-10 text-right mb-10">1 ponto</p>
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex flex-column mr-10 w-100">
                      <Radio.Button value={0} className="w-100 mb-10">
                        Outros
                      </Radio.Button>
                      <p className="f-14 mt-10 text-right mb-10">Não</p>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p className="blue bold">Fuma quando está doente e acamado?</p>
              <Form.Item name={"fuma_quando_esta_doente"} className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Row className="w-100">
                    <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex flex-column mr-10 w-100">
                      <Radio.Button value={1} className="w-100 mb-10">
                        Sim
                      </Radio.Button>
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex flex-column mr-10 w-100">
                      <Radio.Button value={0} className="w-100 mb-10">
                        Não
                      </Radio.Button>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10 text-center mb-10">Sim = 1 ponto | Não = 0</p>
            </Col>
          </Row>
        </Form>
      </CalculatorContent>

      {resultPoints && resultMessage && (
        <CalculatorResult>
          <Row gutter={[{}, { xs: 40, sm: 8, md: 60, lg: 60 }]} className="h-100 ai-start jc-start">
            <Col xs={24} sm={18} md={6} lg={6} xl={6}>
              <p className="f-16 text-uppercase mb-10">Resultado:</p>
              <h3 className="f-30 bold">{resultPoints}</h3>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18} xl={18}>
              <h3 className="f-24 bold text-uppercase">{resultMessage}</h3>
              <p className="f-15 mt-10 mb-10">
                A pontuação máxima é de 10. Os fumadores que obtenham uma pontuação de 6 ou mais podem considerar-se muito dependentes. Os que obtenham uma pontuação inferior a 6,
                pouco dependentes.{" "}
              </p>
              <p className="f-15">
                Fonte: Adaptado de Heatherton, T. F., Kozlowski, L. T., Frecker, R. C., Fagerström, K. O., “The Fagerström Test for Nicotine Dependence: a revision of the
                Fagerström Tolerance Questionnaire”, British Journal of Addiction, 1991; 86(9):1119-1127
              </p>
            </Col>
          </Row>
        </CalculatorResult>
      )}

      <CalculatorLinks />
    </>
  );
};
export default CalculatorFagerstromPage;
