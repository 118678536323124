import React, { useState } from "react";
import { Button, Form, Radio, Drawer, Select, message, Input, Divider, DatePicker } from "antd";
import axios from "axios";

import endpoints from "../../../services/endpoints";
import districts from "../../../services/districts";

import "../../../assets/styles/admin.css";
import "react-quill/dist/quill.snow.css";

function Create({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [counties, setCounties] = useState([]);

  const [form] = Form.useForm();

  function handleClose() {
    form.resetFields();
    close();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);
    const formData = new FormData();

    axios
      .post(endpoints.user.create, {
        data: values,
      })
      .then((res) => {
        if (res.data.user_registered) {
          setTimeout(() => {
            setIsButtonLoading(false);
            handleClose();
            form.resetFields();
          }, 1000);
        } else {
          message.error({ content: res.data.message });
          setIsButtonLoading(false);
        }
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  function handleChangeDistrict(e) {
    form.setFieldValue("county", null);
    let auxCounties = districts.filter((item) => item.name === e)[0];
    setCounties(auxCounties.county);
  }

  function handleGeneratePassword() {
    let chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let passwordLength = 8;
    let password = "";
    for (var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }

    form.setFieldValue("password", password);
  }

  return (
    <Drawer
      className="drawer-user"
      key={`drawer-create-user`}
      title="Criar utilizador"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Button disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
            Cancelar
          </Button>
          <Button loading={isButtonLoading} type="primary" onClick={form.submit}>
            Criar
          </Button>
        </>
      }
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Nome" size="large" />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Nome" size="large" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input.Password
            addonAfter={
              <Button className="minh-38 maxh-38" type="primary" onClick={handleGeneratePassword}>
                Generate Password
              </Button>
            }
            placeholder="Password"
            size="large"
          />
        </Form.Item>
        <Divider />
        <p className="f-16 blue mb-30">Dados pessoais</p>
        <Form.Item
          label="Data de nascimento"
          name="birth_date"
          rules={[
            {
              required: true,
              message: "Introduza a sua data de nascimento",
            },
          ]}
        >
          <DatePicker
            size="large"
            placeholder="Data"
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentNode;
            }}
          />
        </Form.Item>
        <Form.Item label="Número da Ordem dos Médicos" name="order_nr">
          <Input size="large" placeholder="Nº Ordem dos Médicos" />
        </Form.Item>
        <Form.Item
          label="Especialidade"
          name="specialty"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            placeholder="Profissão"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            options={[
              {
                value: "Cardiologia",
                label: "Cardiologia",
              },
              {
                value: "MGF",
                label: "MGF",
              },
              {
                value: "Medicina Interna",
                label: "Medicina Interna",
              },
              {
                value: "Outra",
                label: "Outra",
              },
            ]}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </Form.Item>
        <Divider />
        <p className="f-16 blue mb-30">Trabalho</p>
        <Form.Item label="Distrito" name="work_district">
          <Select
            size="large"
            showSearch
            placeholder="Distrito"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            onChange={handleChangeDistrict}
            options={districts.map((item) => ({
              value: item.name,
              label: item.name,
            }))}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </Form.Item>
        <Form.Item label="Concelho" name="work_county">
          <Select
            size="large"
            showSearch
            placeholder="Concelho"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            options={counties.map((item) => ({
              value: item.name,
              label: item.name,
            }))}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </Form.Item>
        <Form.Item label="Local" name="work_local">
          <Input size="large" placeholder="Local" />
        </Form.Item>
        <Divider />
        <Form.Item
          label="Enviar e-mail ao utilizador"
          name="send_email"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Radio.Group>
            <Radio value={1}>Sim</Radio>
            <Radio value={0}>Não</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default Create;
