import React, { useEffect, useState } from "react";
import { Col, Row, Select, Button, Form, Input } from "antd";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";

import config from "../../../services/config";
import FiltroIcon from "../../../assets/images/filtro-icon.svg";
import SearchIcon from "../../../assets/images/search-icon.svg";

import "./ListArticles.css";

const ListArticles = ({ data, medicalAreasData }) => {
  const [articles, setArticles] = useState(data);
  const [medicalAreas, setMedicalAreas] = useState(medicalAreasData);
  const [filteredArticles, setFilteredArticles] = useState(data);
  const [inputFilter, setInputFilter] = useState("");
  const [itemsToShow, setItemsToShow] = useState(8);

  const [form] = Form.useForm();

  useEffect(() => {
    preLoadImages();
    setArticles(data);
    setMedicalAreas(medicalAreasData);
    setFilteredArticles(data.slice(4));
  }, [data, medicalAreasData]);

  function preLoadImages() {
    data.forEach((e) => {
      var img_banner = new Image();
      img_banner.src = e.image_banner;
      var img_thumb = new Image();
      img_thumb.src = e.image_thumbnail;
    });
  }

  function handleFilter(changedFields, allFields) {
    let auxFilteredArticles = [];

    if (allFields.medical_areas && allFields.medical_areas.length > 0) {
      for (let i = 0; i < articles.length; i++) {
        if (articles[i].medical_areas) {
          let auxMedicalAreas = JSON.parse(articles[i].medical_areas);
          if (allFields.medical_areas.some((v) => auxMedicalAreas.includes(v))) {
            auxFilteredArticles.push(articles[i]);
          }
        }
      }
    } else {
      auxFilteredArticles = articles;
    }

    if (allFields.title) {
      auxFilteredArticles = auxFilteredArticles.filter((item) => item.title.toLowerCase().includes(allFields.title.toLowerCase()));
    }

    setFilteredArticles(auxFilteredArticles);
  }

  const handleLoadMore = () => {
    setItemsToShow(itemsToShow + 6);
  };

  return (
    <Row gutter={[24]} className="container mt-60">
      <Col xs={24} sm={24} md={6} lg={6} xl={6} className="mb-40">
        <Form form={form} onValuesChange={handleFilter} className="w-100">
          <Row className="d-flex flex-nowrap mb-20">
            <div className="d-flex jc-center ai-center">
              <img src={SearchIcon} width={"25px"} />
            </div>
            <Form.Item name="title" className="mb-0 w-100">
              <Input size="large" allowClear value={inputFilter} onChange={(e) => setInputFilter(e.target.value)} placeholder="Procurar artigo" className="ml-5" />
            </Form.Item>
          </Row>
          <Row className="d-flex flex-nowrap">
            <div className="d-flex jc-center ai-center">
              <img src={FiltroIcon} width={"25px"} alt="filtro icon" className="mb-10" />
            </div>
            <Form.Item name="medical_areas" className="mb-0 w-100">
              <Select
                mode="multiple"
                size="large"
                allowClear
                showSearch
                placeholder="Selecionar área médica"
                optionFilterProp="children"
                options={medicalAreas.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                className="ml-5 w-100"
              />
            </Form.Item>
          </Row>
        </Form>
      </Col>
      <Col xs={24} sm={24} md={18} lg={18} xl={18}>
        <Row gutter={[{ xs: 0, sm: 0, md: 0, lg: 24 }, 24]} className="list-articles-row">
          {filteredArticles?.slice(0, itemsToShow).map((item, index) => {
            return (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} key={"article-" + item?.id}>
                <Link to={`/artigos/${item?.slug}`}>
                  <div className="list-article-item">
                    <div
                      className="image"
                      style={{
                        backgroundImage: `url(${config.server_ip}/assets/media/${item?.image_thumbnail})`,
                      }}
                    ></div>
                    <div className="d-flex jc-sb w-100 p-20 list-article-item-text">
                      <div className="d-flex flex-column jc-center">
                        <p className="f-16 blue ellipsisSix">{item?.title}</p>
                      </div>
                      <div className="minw-100 d-flex flex-column jc-end ai-center link">
                        <p>ler artigo</p>
                        <span>
                          <ArrowRightOutlined />
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
            );
          })}
        </Row>
        <Row className="d-flex jc-center ai-center mt-40 mb-40">
          {filteredArticles && itemsToShow < filteredArticles?.length && (
            <Button className="btn-ver-mais" onClick={handleLoadMore}>
              Explorar mais artigos
            </Button>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default ListArticles;
