import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Col, Row, Button } from "antd";
import { Link as LinkScroll } from "react-scroll";

import PodcastsBg from "../../../assets/images/banner-podcast.png";
import SpotifyImage from "../../../assets/images/logotipo-spotify.svg";
import ApplePodcastsImage from "../../../assets/images/logotipo-apple-podcasts.svg";

import "./BannerPodcasts.css";
import "../../../assets/styles/podcasts.css";

const BannerPodcasts = () => {
  return (
    <Row className="banner_podcast" style={{ backgroundImage: `url(${PodcastsBg})` }}>
      <Col span={24} className="container d-flex flex-column jc-center">
        <Row className="login-row" align={"middle"}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="banner-podcast-col">
            <div className="banner-podcast-destaque">
              <h2 className="title-podcast-banner">
                Podcasts de atualização <br />
                científica
              </h2>
              <h6 className="content-podcast-banner">
                O Cardio4ALL é uma nova iniciativa de formação médica dirigida a todos os não cardiologistas. O objetivo é trazer formação científica de alta qualidade, baseada na
                prática clínica e de forma interativa.
              </h6>
              <p className="content-podcast-banner">Ouça agora os nossos podcasts já disponíveis, através da plataforma de Spotify ou através da Apple Podcasts.</p>
              <Row gutter={[32, 32]} className="spotify-podcast-row">
                <Col>
                  <img src={SpotifyImage} />
                </Col>
                <Col>
                  <img src={ApplePodcastsImage} />
                </Col>
              </Row>
              <Button type="primary" className="comecar-ouvir-btn">
                <a href="https://open.spotify.com/show/3N01LecX6UVZ6kq9oU40xO" target="_blank" rel="noreferrer">
                  Começar a ouvir
                </a>
              </Button>
            </div>
          </Col>
          <Col span={12}></Col>
        </Row>
      </Col>
      <LinkScroll className="arrow_down" activeClass="active" to="section-list-podcasts" spy={true} smooth={true} offset={-70} duration={500}>
        <DownOutlined style={{ color: "#FFF" }} />
      </LinkScroll>
    </Row>
  );
};

export default BannerPodcasts;
