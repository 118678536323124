import React, { useState, useEffect } from "react";
import { Col, Row, Button, Table, Input, Form } from "antd";
import { EditOutlined, DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";

import Update from "../../../components/dashboard/user/Update";
import Create from "../../../components/dashboard/user/Create";
import Import from "../../../components/dashboard/user/Import";
import Delete from "../../../components/dashboard/user/Delete";
import DrawerExportTable from "../../../components/dashboard/Export/DrawerExportTable";
import endpoints from "../../../services/endpoints";

import "../../../assets/styles/admin.css";

const DashboardUsers = () => {
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenImport, setIsOpenImport] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenExportDrawer, setIsOpenExportDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "E-mail",
      dataIndex: "email",
    },
    {
      title: "Data Nascimento",
      dataIndex: "birth_date",
    },
    {
      title: "Nr Ordem Médicos",
      dataIndex: "order_nr",
    },
    {
      title: "Profissão",
      dataIndex: "profession",
    },
    {
      title: "",
      dataIndex: "actions",
    },
  ];

  useEffect(() => {
    handleGetUsers();
  }, []);

  function handleGetUsers() {
    setIsLoading(true);
    axios
      .get(endpoints.user.readDashboard)
      .then((res) => {
        setUsers(res.data);
        handlePrepareData(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  function handlePrepareData(data) {
    setIsLoading(true);
    let aux = [];
    for (let i = 0; i < data.length; i++) {
      let auxObj = {
        id: data[i].id,
        name: data[i].name,
        email: data[i].email,
        birth_date: dayjs(data[i].birth_date).format("DD-MM-YYYY"),
        order_nr: data[i].order_nr,
        profession: data[i].specialty,
        actions: (
          <>
            <Button className="mr-10" onClick={() => handleOpenUpdate(data[i])}>
              <EditOutlined />
            </Button>
            <Button onClick={() => handleOpenDelete(data[i])}>
              <DeleteOutlined />
            </Button>
          </>
        ),
        full_data: data[i],
      };

      aux.push(auxObj);
    }

    setIsLoading(false);
    setTableData(aux);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetUsers();
  }

  function handleOpenUpdate(data) {
    setSelectedUser(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetUsers();
  }

  function handleOpenDelete(data) {
    setSelectedUser(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedUser({});
    setIsOpenDelete(false);
    handleGetUsers();
  }

  function handleOpenImport() {
    setIsOpenImport(true);
  }

  function handleCloseImport() {
    setIsOpenImport(false);
    handleGetUsers();
  }

  function handleSearch(e) {
    console.log(e);
    let searchString = e.search;
    let filterData = users.filter((item) => item.email.includes(searchString) || item.name.toLowerCase().includes(searchString));
    handlePrepareData(filterData);
  }

  function handleOpenExport() {
    setIsOpenExportDrawer(true);
  }

  function handleCloseExport() {
    setIsOpenExportDrawer(false);
  }

  return (
    <Row>
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Import open={isOpenImport} close={handleCloseImport} />
      <Update open={isOpenUpdate} close={handleCloseUpdate} data={selectedUser} />
      <Delete open={isOpenDelete} close={handleCloseDelete} data={selectedUser} />
      <DrawerExportTable open={isOpenExportDrawer} close={handleCloseExport} data={tableData} />
      <Col span={24}>
        <Row className="title-admin-page d-flex jc-sb ai-center">
          <Col span={6}>
            <h2 className="m-0">Utilizadores</h2>
          </Col>
          <Col span={18}>
            <div className="d-flex jc-end ai-center">
              <Form onValuesChange={handleSearch} style={{ maxWidth: 400, borderRadius: 0 }}>
                <Form.Item name="search" className="mb-0 mr-10">
                  <Input size="large" className="" style={{ maxWidth: 400, borderRadius: 0 }} placeholder="Procurar..." />
                </Form.Item>
              </Form>
              <Button className="mr-10" size="large" onClick={handleGetUsers}>
                <ReloadOutlined />
              </Button>
              <Button className="mr-10" size="large" onClick={handleOpenExport}>
                Exportar
              </Button>
              <Button className="mr-10" size="large" onClick={handleOpenImport}>
                Importar
              </Button>
              <Button size="large" onClick={handleOpenCreate}>
                Adicionar
              </Button>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Table loading={isLoading} columns={columns} dataSource={tableData} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DashboardUsers;
