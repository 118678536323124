import React from "react";
import { Table } from "antd";

import "react-quill/dist/quill.snow.css";

import "../../../assets/styles/admin.css";

function ExportData({ data, columns }) {
  return (
    <div className="d-flex flex-column jc-center ai-center p-20">
      <p className="blue f-20 mb-30 mt-30">Vão ser exportados:</p>
      <p className="bold blue f-40 mt-15">{data.length}</p>
      <p className="blue f-20 mb-30">utilizadores</p>
      <Table columns={columns} dataSource={data} scroll={{ x: 1 }} />
    </div>
  );
}

export default ExportData;
