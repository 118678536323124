import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Col, Row, Button } from "antd";

import "./ListCourses.css";

const ListCourses = ({ data }) => {
  const [itemsToShow, setItemsToShow] = useState(10);

  const handleLoadMore = () => {
    setItemsToShow(itemsToShow + 10);
  };

  return (
    <Row id="section-list-videos" className="container mt-60">
      <Col span={24} className="d-flex flex-column jc-center ai-center">
        <Row
          gutter={[
            { xs: 40, sm: 8, md: 16, lg: 24 },
            { xs: 40, sm: 8, md: 16, lg: 24 },
          ]}
          className="mr-0 ml-0 d-flex"
        >
          {data?.slice(0, itemsToShow + 1).map((item, index) => {
            return (
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                className="d-flex flex-column mb-20"
                //onClick={() => handleOpenModalVideo(item)}
              >
                <div className="player-wrapper">
                  <ReactPlayer className="react-player" controls volume={1} url={item?.link} />
                </div>
                <p className="blue f-18 mb-10 mt-10">{item?.title}</p>
                {item?.description && <p className="f-16 normal ellipsis_list_description">{item?.description}</p>}
              </Col>
            );
          })}
          {data && itemsToShow < data?.length && (
            <Row className="d-flex jc-center ai-center mt-40 mb-40">
              <Button className="btn-ver-mais" onClick={handleLoadMore}>
                Explorar mais videos
              </Button>
            </Row>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default ListCourses;
