import React, { useState } from "react";
import { Col, Form, Row, Button, Radio, Slider, Input, Tooltip } from "antd";
import { Helmet } from "react-helmet";

import CalculatorBanner from "../../../components/website/CalculatorBanner/CalculatorBanner";
import { CalculatorLinks } from "../../../components/website/CalculatorLinks/CalculatorLinks";
import { CalculatorResult } from "../../../components/website/CalculatorResult/CalculatorResult";
import { CalculatorContent } from "../../../components/website/CalculatorContent/CalculatorContent";

import "./Calculators.css";
import "../../../assets/styles/calculator.css";

/* CALCULADORA CKD-EPI (2021) */
const CalculatorCKDEPI2021Page = () => {
  const [minValues] = useState({ age: 27 });
  const [maxValues] = useState({ age: 97 });
  const [isResultLoading, setIsResultLoading] = useState(false);
  const [errorFinded, setErrorFinded] = useState(null);
  const [resultMessage, setResultMessage] = useState("");
  const [form] = Form.useForm();

  function handleIncrement(key) {
    let value = form.getFieldValue(key);
    if (typeof value === "string") {
      value = Number(value);
    }

    if (value) {
      if (value >= minValues[key] && value < maxValues[key]) {
        value++;
      } else if (value < minValues[key]) {
        value = minValues[key];
      } else {
        value = maxValues[key];
      }

      form.setFieldValue([key], Number(value));
      form.validateFields([key]);
    } else {
      form.setFieldValue([key], minValues[key]);
      form.validateFields([key]);
    }
  }

  function handleDecrement(key) {
    let value = form.getFieldValue(key);
    if (typeof value === "string") {
      value = Number(value);
    }

    if (value) {
      if (value > minValues[key] && value <= maxValues[key]) {
        value--;
      } else if (value <= minValues[key]) {
        value = minValues[key];
      } else {
        value = maxValues[key];
      }

      form.setFieldValue([key], Number(value));
      form.validateFields([key]);
    } else {
      form.setFieldValue([key], minValues[key]);
      form.validateFields([key]);
    }
  }

  const handleValuesChange = () => {
    let allValues = form.getFieldsValue();
    let columns = Object.keys(allValues);

    let nullItems = 0;
    let auxErrorsFinded = 0;
    let sumPoints = 0;

    for (let i = 0; i < columns.length; i++) {
      if (typeof allValues[columns[i]] === "string") {
        allValues[columns[i]] = Number(allValues[columns[i]]);
      }

      if (allValues[columns[i]] === null || allValues[columns[i]] === undefined) {
        nullItems++;
      }
    }

    if (nullItems === 0) {
      const validationErrors = Object.values(form.getFieldsError());

      for (let e = 0; e < validationErrors.length; e++) {
        if (validationErrors[e].errors.length > 0) {
          auxErrorsFinded++;
        }
      }
    }

    if (nullItems === 0 && auxErrorsFinded === 0) {
      if (allValues["creatinine"] === 0 || allValues["sex"] == null) {
        setResultMessage("");
      }

      let sex = allValues["sex"];
      let creatinine = allValues["creatinine"];
      let age = allValues["age"];

      let k_val = sex === 1 ? 0.9 : 0.7;
      let a_val = sex === 1 ? -0.302 : -0.241;
      let s_val = sex === 1 ? 1 : 1.012;

      let min_val = 0;
      let max_val = 0;

      if (creatinine / k_val > 1) {
        min_val = 1;
        max_val = creatinine / k_val;
      } else {
        min_val = creatinine / k_val;
        max_val = 1;
      }

      sumPoints = 142 * Math.pow(min_val, a_val) * Math.pow(max_val, -1.2) * Math.pow(0.9938, age) * s_val;

      if (!isNaN(Math.round(sumPoints))) {
        setResultMessage(Math.round(sumPoints) + " ml/min/1.73 m²");
        setIsResultLoading(false);
        setErrorFinded(false);
      }
    } else {
      if (auxErrorsFinded > 0) {
        setErrorFinded(true);
      }
      setResultMessage(null);
    }
  };

  function handleValidate(rule, value) {
    let key = rule.field;
    let keysOfNumber = Object.keys(minValues);
    if (keysOfNumber.includes(key)) {
      if (Number(value) < minValues[rule.field]) {
        return Promise.reject(<Tooltip color="red" placement="bottom" open={true} title={`Este valor não pode ser menor que ${minValues[rule.field]}`}></Tooltip>); // The validator should always return a promise on both success and error
      } else if (Number(value) > maxValues[rule.field]) {
        return Promise.reject(<Tooltip color="red" placement="bottom" open={true} title={`Este valor não pode ser maior que ${maxValues[rule.field]}`}></Tooltip>); // The validator should always return a promise on both success and error
      } else {
        return Promise.resolve(); // The validator should always return a promise on both success and error
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Calculadora CKD-EPI (2021) | Formação Científica em Medicina Cardiovascular</title>
        <meta
          name="description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta
          name="keywords"
          content="calculadoras área cardiovascular, Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Calculadora CKD-EPI (2021) | Formação Científica em Medicina Cardiovascular" />
        <meta
          property="og:description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
      </Helmet>
      <CalculatorBanner title="CKD-EPI (2021)" />

      <CalculatorContent className="mt-10">
        <Form className="form_calculator" form={form} onFieldsChange={handleValuesChange}>
          {/* Row #1 */}
          <Row
            gutter={[
              { xs: 20, sm: 8, md: 16, lg: 60 },
              { xs: 40, sm: 8, md: 16, lg: 60 },
            ]}
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Creatina sérica (mg/dL)</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Form.Item name={"creatinine"} className="mt-10 w-100 mb-0">
                  <Slider min={0.3} max={6.0} step={0.01} />
                </Form.Item>
              </Row>
              <Row className="jc-sb">
                <span style={{ color: "#C3D0DA" }}>0.3</span>
                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.creatinine !== currentValues.creatinine}>
                  {({ getFieldValue }) => (getFieldValue("creatinine") ? <span className="bold blue">{form.getFieldValue("creatinine")}</span> : null)}
                </Form.Item>
                <span style={{ color: "#C3D0DA" }}>6.0</span>
              </Row>
            </Col>
          </Row>

          {/* Row #2 */}
          <Row
            gutter={[
              { xs: 20, sm: 8, md: 16, lg: 60 },
              { xs: 40, sm: 8, md: 16, lg: 60 },
            ]}
            className="mt-30"
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Sexo</p>
              <Form.Item name={"sex"} className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={0} className="w-100 mr-10">
                    Feminino
                  </Radio.Button>
                  <Radio.Button value={1} className="w-100 ml-10">
                    Masculino
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Idade</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("age")} className="calculator_button mr-10">
                  -
                </Button>
                <Form.Item name={"age"} className="w-100 mb-0" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("age")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
              <p className="f-14 mt-10">Valor entre: 27 - 97</p>
            </Col>
          </Row>
        </Form>
      </CalculatorContent>

      {resultMessage && (
        <CalculatorResult isLoading={isResultLoading}>
          <Row className="h-100 ai-center jc-start">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <p className="f-16 text-uppercase mb-10">Resultado:</p>
              <h3 className="f-30 bold">{resultMessage}</h3>
            </Col>
          </Row>
        </CalculatorResult>
      )}

      {errorFinded && (
        <CalculatorResult isLoading={isResultLoading}>
          <Row gutter={[24, 24]} className="h-100 ai-center jc-center">
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className="d-flex jc-center ai-center">
              <p className="f-20 text-center">Algum valor não está entre os parâmetros requeridos</p>
            </Col>
          </Row>
        </CalculatorResult>
      )}

      <CalculatorLinks />
    </>
  );
};
export default CalculatorCKDEPI2021Page;
