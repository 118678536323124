import React, { useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import axios from "axios";

import endpoints from "../../../services/endpoints";

import "../../../assets/styles/admin.css";

function Create({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios
      .post(endpoints.tags.create, {
        data: values,
      })
      .then((res) => {
        setIsButtonLoading(false);
        form.resetFields();
        close({ id: res.insertId, name: values.name });
      })
      .catch((e) => {
        setIsButtonLoading(false);
        console.log(e);
      });
  }

  function handleClose() {
    form.resetFields();
    close();
  }

  return (
    <Modal
      className="modal-create-tag p-20"
      key={`modal-create-tag`}
      title="Criar tag"
      width={800}
      onCancel={handleClose}
      open={open}
      footer={
        <>
          <Button disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
            Cancelar
          </Button>
          <Button loading={isButtonLoading} type="primary" onClick={form.submit}>
            Criar
          </Button>
        </>
      }
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input size="large" placeholder="Nome" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default Create;
