import React, { useEffect, useState } from "react";
import { Outlet, useLocation, Link, useSearchParams } from "react-router-dom";
import { Layout, Button, Menu, Row, Col, Divider, Tooltip } from "antd";
import { DashboardOutlined, FacebookOutlined, InstagramOutlined, LoginOutlined, LogoutOutlined, MenuOutlined, MailOutlined } from "@ant-design/icons";
import { UserOutlined } from "@ant-design/icons";
import { Footer } from "antd/es/layout/layout";
import axios from "axios";
import { animateScroll as scroll } from "react-scroll";

import endpoints from "../../services/endpoints";
import ModalLogout from "../../components/website/ModalLogout/ModalLogout";
import DrawerMenuMobile from "./DrawerMenuMobile";

import Logo from "../../assets/images/logo-cardio4all.svg";
import FooterLogo from "../../assets/images/cardio4all-footer.svg";
import instagramIcon from "../../assets/images/instagram-white.svg";
import facebookIcon from "../../assets/images/facebook-white.svg";
import footerBg from "../../assets/images/fundo-footer.png";
import ScrollToTop from "../../assets/images/ScrollToTop.svg";
import bialWhite from "../../assets/images/BIAL-white.png";

import "./MainLayout.css";

const { Header, Content } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const MainLayout = ({ user, isLoggedIn, handleLogOut, headerHidden }) => {
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  let pathname = location.pathname.split("/");

  const [current, setCurrent] = useState(pathname[pathname.length - 1]);
  const [courses, setCourses] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false);
  const [isOpenModalLogout, setIsOpenModalLogout] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [items, setItems] = useState([
    {
      label: (
        <Link to="https://2024.cardio4all.pt/home" target="_blank" className="event-button">
          Cardio4All 2024
        </Link>
      ),
      key: "/cardio4all2024",
    },
    { label: <Link to="/calculadoras">Calculadoras</Link>, key: "/calculadoras" },
    { label: <Link to="/cursos">Cursos</Link>, key: "/cursos" },
    { label: <Link to="/videocasts">Videocasts</Link>, key: "/videocasts" },
    { label: <Link to="/guias-praticos">Guias Práticos</Link>, key: "/guias-praticos" },
    { label: <Link to="/artigos">New Science</Link>, key: "/artigos" },
    { label: <Link to="/casos-clinicos">Casos clínicos</Link>, key: "/casos-clinicos" },
  ]);

  useEffect(() => {
    if (pathname.length > 2) {
      setCurrent(`/${pathname[1]}`);
    } else {
      setCurrent(`/${pathname[pathname.length - 1]}`);
    }

    setIsOpenMenuMobile(false);

    if (!location.pathname.includes("admin")) {
      document.body.classList.remove("admin-dashboard");
    }
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    handleGetChatSupport();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleGetChatSupport() {
    axios
      .get(endpoints.settings.read)
      .then((res) => {
        let chatData = res.data.filter((item) => item.name === "chat")[0];
        let linkOfChat = JSON.parse(chatData.json);
        if (linkOfChat.link) {
          const script = document.createElement("script");

          script.src = linkOfChat.link;
          script.async = true;

          document.body.appendChild(script);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleOpenDrawerMenuMobile() {
    setIsOpenMenuMobile(true);
  }

  function handleCloseDrawerMenuMobile() {
    setIsOpenMenuMobile(false);
  }

  function handleOpenModalLogout() {
    setIsOpenModalLogout(true);
  }

  function handleCloseModalLogout() {
    setIsOpenModalLogout(false);
  }

  return (
    <Layout>
      <ModalLogout handleLogout={handleLogOut} open={isOpenModalLogout} close={handleCloseModalLogout} />
      <DrawerMenuMobile user={user} open={isOpenMenuMobile} close={handleCloseDrawerMenuMobile} handleLogout={handleOpenModalLogout} menuItems={items} />
      <Header className={`web`} style={{ display: headerHidden ? "none" : "inherit" }}>
        <Row className="bg-blue w-100 mobile-hidden" style={{ height: "40px" }}>
          <Row className="container p-0 h-100">
            <Col span={12} className="d-flex h-100">
              <div className="d-flex jc-start ai-center pl-20">
                <Link to="https://www.facebook.com/cardio4all.pt/" target="_blank" className="social_link">
                  <FacebookOutlined className="white f-18 mr-10" />
                </Link>
                <Link to="https://www.instagram.com/cardio_4all/" target="_blank" className="social_link">
                  <InstagramOutlined className="white f-18 mr-10" />
                </Link>
                <Link to="mailto:cardio4all@gmail.com" className="social_link">
                  <MailOutlined className="white f-18" />
                </Link>
              </div>
            </Col>
            <Col span={12} className="d-flex jc-end h-100">
              <div className="minw-200 d-flex jc-end ai-center">
                {isLoggedIn && user ? (
                  user.is_admin ? (
                    <>
                      <Tooltip placement="bottom" title={"Logout"}>
                        <Button className="login-extra-btn" icon={<LogoutOutlined className="f-18" />} onClick={handleOpenModalLogout}></Button>
                      </Tooltip>
                      <Tooltip placement="bottom" title={"Backoffice"}>
                        <Link to="/admin" className="social_link">
                          <Button className="login-extra-btn" icon={<DashboardOutlined className="f-18" />}></Button>
                        </Link>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip placement="bottom" title={"Logout"}>
                        <Button className="login-extra-btn mr-10" icon={<LogoutOutlined className="f-18" />} onClick={handleOpenModalLogout}></Button>
                      </Tooltip>
                      <Tooltip placement="bottom" title={"Perfil"}>
                        <Link to="/perfil" className="social_link">
                          <Button className={`login-extra-btn ${location.pathname === "/perfil" ? "is_active" : ""}`} icon={<UserOutlined className="f-18" />}></Button>
                        </Link>
                      </Tooltip>
                    </>
                  )
                ) : (
                  <Link to="/login" className="social_link">
                    <Button className="login-extra-btn d-flex jc-center ai-center" icon={<LoginOutlined className="f-18" />}>
                      <p className="m-0">Login</p>
                    </Button>
                  </Link>
                )}
              </div>
            </Col>
          </Row>
        </Row>
        <div className="content_header d-flex jc-sb ai-center">
          <div className="logo minw-200 maxh-40 d-flex jc-center ai-center">
            <Link to="/" className="d-flex jc-center ai-center h-100">
              <img src={Logo} className="w-100 h-100 maxh-40" alt="logo" />
            </Link>
          </div>
          <div className="desktop-display d-flex jc-center ai-center w-100">
            <Menu id="principal_menu" key="principal_menu_key" className="w-100 d-flex jc-end ai-center" mode="horizontal" selectedKeys={[current]} items={items} />
          </div>

          <div className="mobile-display">
            <MenuOutlined onClick={handleOpenDrawerMenuMobile} className="icon_menu_mobile" />
          </div>
        </div>
      </Header>
      <Content className={`site_content`} style={{ marginTop: headerHidden ? "0px" : windowWidth < 640 ? "80px" : "120px" }}>
        <Outlet user={user} />
        {showScrollTop && <img src={ScrollToTop} className="arrow_scrolltop" onClick={() => scroll.scrollTo(0)} />}
      </Content>
      <Footer className="p-0" style={{ display: headerHidden ? "none" : "block" }}>
        <Row className="p-30 pt-20" style={{ backgroundImage: `url(${footerBg})` }}>
          <Col span={24} className="footer-container">
            <Row gutter={[24]} wrap>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} className="d-flex jc-center ai-center mt-20">
                <Link to="/">
                  <img src={FooterLogo} className="w-100" style={{ maxWidth: "300px" }} />
                </Link>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} className="mt-20">
                <ul className="footer-menu">
                  <li>
                    <b>CARDIO4ALL SUMMIT 2024</b>
                  </li>
                  <li>
                    <Link to="https://2024.cardio4all.pt/#programa" target="_blank">
                      Programa
                    </Link>
                  </li>
                  <li>
                    <Link to="https://2024.cardio4all.pt/inscricoes/" target="_blank">
                      Inscrições
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} className="mt-20">
                <ul className="footer-menu">
                  <li>
                    <Link to="/calculadoras">Calculadoras</Link>
                  </li>
                  <li>
                    <Link to="/guias-praticos">Guias práticos</Link>
                  </li>
                  <li>
                    <Link to="/cursos">Cursos</Link>
                  </li>
                  <li>
                    <Link to="/newsletters">Newsletters</Link>
                  </li>
                </ul>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} className="mt-20">
                <ul className="footer-menu">
                  <li>
                    <Link to="/artigos">New science</Link>
                  </li>
                  <li>
                    <Link to="/videocasts">Videocasts</Link>
                  </li>
                  <li>
                    <Link to="/casos-clinicos">Casos clínicos</Link>
                  </li>
                  <li>
                    <Link to="/sugestoes">Sugestões</Link>
                  </li>
                  <li>
                    <Link to="/contacto">Contacto</Link>
                  </li>
                </ul>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} className="d-flex flex-column jc-center ai-center mt-20">
                <Row className="d-flex jc-center ai-center">
                  <a className="mr-20" href="https://www.facebook.com/cardio4all.pt/" target="_blank" rel="noreferrer">
                    <img src={facebookIcon} alt="Facebook icon" width={"22px"} />
                  </a>
                  <a href="https://www.instagram.com/cardio_4all/" target="_blank" rel="noreferrer">
                    <img src={instagramIcon} alt="Instagram icon" width={"22px"} />
                  </a>
                </Row>
                <Row className="d-flex jc-center ai-center m-20">
                  <a className="footer-link" href="mailto:cardio4all@gmail.com">
                    cardio4all@gmail.com
                  </a>
                </Row>
                <Row className="d-flex jc-center ai-center m-20">
                  <Col span={24}>
                    <div className="d-flex flex-column flex-end ai-center">
                      <p className="m-0 f-14 white">Com o patrocínio independente de:</p>
                      <a href="https://bial.com/" target="_blank">
                        <img src={bialWhite} className="w-100 maxw-100" />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="d-flex jc-center ai-center text-center footer-tabs">
              <a href="/politica-de-privacidade">Política de privacidade</a>
              <Divider type="vertical" style={{ color: "#fff" }} />
              <a href="politica-de-cookies">Política de cookies</a>
              <Divider type="vertical" />
              <a href="termos-e-condicoes">Termos e condições</a>
            </Row>
          </Col>
        </Row>
        <Row style={{ backgroundColor: "#1C1C1C" }} className="p-10 d-flex jc-center ai-center text-center">
          <Col span={24} className="d-flex jc-center ai-center">
            <p className="f-12 white">© Copyright 2024 | Cardio4all | Todos os direitos reservados</p>
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
};
export default MainLayout;
