import React, { useState, useEffect } from "react";
import { Col, Row, Button, Modal, Card, Empty, Pagination } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";

import Update from "../../../components/dashboard/banner/Update";
import Create from "../../../components/dashboard/banner/Create";
import Delete from "../../../components/dashboard/banner/Delete";
import endpoints from "../../../services/endpoints";
import config from "../../../services/config";

import "../../../assets/styles/admin.css";

const { Meta } = Card;
const { confirm } = Modal;

const DashboardBanners = () => {
  const [banners, setBanners] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState([]);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(8);

  useEffect(() => {
    handleGetBanners();
  }, []);

  function handleGetBanners() {
    axios
      .get(endpoints.banners.read)
      .then((res) => {
        setBanners(res.data);
      })
      .catch((error) => console.error(error));
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetBanners();
  }

  function handleOpenUpdate(data) {
    setSelectedBanner(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetBanners();
  }

  function handleOpenDelete(data) {
    setSelectedBanner(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedBanner({});
    setIsOpenDelete(false);
    handleGetBanners();
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  return (
    <Row>
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update open={isOpenUpdate} close={handleCloseUpdate} data={selectedBanner} />
      <Delete open={isOpenDelete} close={handleCloseDelete} data={selectedBanner} />
      <Col span={24}>
        <Row className="title-admin-page d-flex jc-sb ai-center">
          <Col>
            <h2 className="m-0">Banners</h2>
          </Col>
          <Col>
            <Button size="large" onClick={handleOpenCreate}>
              Adicionar
            </Button>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          {banners?.slice(minValue, maxValue).map((item) => {
            return (
              <Col span={6} key={item.id}>
                <Card
                  cover={<img alt={item.image_desktop} src={`${config.server_ip}/assets/media/${item.image_desktop}`} />}
                  actions={[<EditOutlined key="edit" onClick={() => handleOpenUpdate(item)} />, <DeleteOutlined key="delete" onClick={() => handleOpenDelete(item)} />]}
                >
                  <Meta title={item.title} description={<div dangerouslySetInnerHTML={{ __html: item.subtitle }}></div>} />
                </Card>
              </Col>
            );
          })}

          <Row className="ml-0 mr-0" align="center">
            <Pagination showSizeChanger={false} onChange={handleChangePage} pageSize={itemsPerPage} defaultCurrent={1} current={currentPage} total={banners.length} />
          </Row>
          {banners.length === 0 && (
            <div style={{ width: "100%", display: "block", margin: "0 auto" }}>
              <Empty description="Não foram encontrados banners" image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default DashboardBanners;
