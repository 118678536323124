import React from "react";
import { Col, Row, Button } from "antd";
import { Link } from "react-router-dom";

import CalculadorasImage from "../../../assets/images/calculadoras-banner@2x.png";

import "./VideocastsBialCTA.css";

const VideocastsBialCTA = () => {
  return (
    <Row className="cta_videocast mt-40">
      <Col span={24} className="container">
        <div className="card">
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={15} lg={15} xl={15} className="pl-80 pt-20 pb-20 d-flex flex-column jc-center">
              <p className="f-24 blue">
                Veja aqui <b>5 mensagens para Guidelines ESC de Diabetes</b> (5 mensagems práticas, em 5 minutos)
              </p>
              <Link to="/videocasts/atualizacao-cientifica" className="mt-20">
                <Button size="large" type="primary" className="mt-20">
                  Ver vídeos
                </Button>
              </Link>
            </Col>
            <Col xs={24} sm={24} md={9} lg={9} xl={9} className="d-flex jc-end ai-end">
              <img src={CalculadorasImage} className="w-100" style={{ height: "auto" }} />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default VideocastsBialCTA;
