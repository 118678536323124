import React, { useState, useEffect } from "react";
import { Col, Row, Button, Modal, Card, Empty, Pagination } from "antd";
import { useParams } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";
import endpoints from "../../../services/endpoints";
import Create from "../../../components/dashboard/coursesItems/Create";
import Update from "../../../components/dashboard/coursesItems/Update";
import Delete from "../../../components/dashboard/coursesItems/Delete";

import "../../../assets/styles/admin.css";

const { Meta } = Card;
const { confirm } = Modal;

export default function DashboardCourseItem() {
  const [course, setCourse] = useState({});
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(20);

  const { id } = useParams();

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      width: 100,
    },
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "Slug",
      dataIndex: "slug",
    },
    {
      title: "",
      dataIndex: "actions",
    },
  ];

  useEffect(() => {
    handleGetBanners();
  }, []);

  function handleGetBanners() {
    axios
      .get(endpoints.courseItem.readById, {
        params: { id_course: id },
      })
      .then((res) => {
        setCourse(res.data[0][0]);
        setData(res.data[1]);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetBanners();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetBanners();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetBanners();
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  return (
    <Row>
      <Create open={isOpenCreate} close={handleCloseCreate} id_course={id} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} id_course={id} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} id_course={id} />
      <Col span={24}>
        <Row className="title-admin-page d-flex jc-sb ai-center">
          <Col>
            <h2 className="m-0">{course.name}</h2>
          </Col>
          <Col>
            <Button size="large" onClick={handleOpenCreate}>
              Adicionar item
            </Button>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          {data?.slice(minValue, maxValue).map((item) => {
            return (
              <Col span={6} key={item.id}>
                <Card
                  cover={
                    <iframe
                      style={{
                        borderTopRightRadius: "8px",
                        borderTopLeftRadius: "8px",
                      }}
                      width="100%"
                      height="220px"
                      frameBorder="0"
                      src={`${item.link}`}
                      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                      loading="lazy"
                    />
                  }
                  actions={[<EditOutlined key="edit" onClick={() => handleOpenUpdate(item)} />, <DeleteOutlined key="delete" onClick={() => handleOpenDelete(item)} />]}
                >
                  <Meta
                    title={item.title}
                    description={
                      <div className="d-flex flex-column">
                        <p>{item.description}</p>
                        <p className="f-14 mt-10">{dayjs(item.date).format("DD-MM-YYYY")}</p>
                      </div>
                    }
                  />
                </Card>
              </Col>
            );
          })}

          {data.length === 0 ? (
            <div style={{ width: "100%", display: "block", margin: "0 auto" }}>
              <Empty description="Não foram encontrados items do curso" image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          ) : (
            <Row className="ml-0 mr-0" align="center">
              <Pagination showSizeChanger={false} onChange={handleChangePage} pageSize={itemsPerPage} defaultCurrent={1} current={currentPage} total={tableData.length} />
            </Row>
          )}
        </Row>
      </Col>
    </Row>
  );
}
