import React, { useState, useEffect } from "react";
import { Col, Row, Button, Empty, Pagination, Spin, Tooltip, Collapse } from "antd";
import { useParams } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";

import Update from "../../../components/dashboard/GuideModule/Update";
import Create from "../../../components/dashboard/GuideModule/Create";
import Delete from "../../../components/dashboard/GuideModule/Delete";
import UpdateTopic from "../../../components/dashboard/GuideTopic/Update";
import DeleteTopic from "../../../components/dashboard/GuideTopic/Delete";
import endpoints from "../../../services/endpoints";

import "../../../assets/styles/admin.css";
import "./Guides.css";

const DashboardGuideModules = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [modules, setModules] = useState([]);
  const [guide, setGuide] = useState({});
  const [calculators, setCalculators] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [selectedTopic, setSelectedTopic] = useState({});
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenUpdateTopic, setIsOpenUpdateTopic] = useState(false);
  const [isOpenDeleteTopic, setIsOpenDeleteTopic] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(8);
  const { id } = useParams();

  useEffect(() => {
    handleGetGuides();
  }, []);

  function handleGetGuides() {
    setIsLoading(true);
    axios
      .get(endpoints.guides.readModules, {
        params: { id },
      })
      .then((res) => {
        setGuide(res.data[0][0]);
        let auxModules = res.data[1];
        let auxTopics = res.data[2];
        let newModules = [];

        for (let i = 0; i < auxModules.length; i++) {
          newModules.push({
            ...auxModules[i],
            topics: auxTopics.filter((item) => item.id_guide_module === auxModules[i].id),
          });
        }

        setModules(newModules);
        setCalculators(res.data[3]);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetGuides();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetGuides();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleOpenDeleteTopic(data) {
    setSelectedData(data);
    setIsOpenDeleteTopic(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetGuides();
  }

  function handleOpenUpdateTopic(topic) {
    setSelectedTopic(topic);
    setIsOpenUpdateTopic(true);
  }

  function handleCloseUpdateTopic() {
    setIsOpenUpdateTopic(false);
    handleGetGuides();
  }

  function handleOpenDeleteTopic(data) {
    setSelectedTopic(data);
    setIsOpenDeleteTopic(true);
  }

  function handleCloseDeleteTopic() {
    setSelectedTopic({});
    setIsOpenDelete(false);
    handleGetGuides();
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  return (
    <Row>
      <Create open={isOpenCreate} close={handleCloseCreate} id_guide={id} />
      <Update open={isOpenUpdate} close={handleCloseUpdate} data={selectedData} />
      <Delete open={isOpenDelete} close={handleCloseDelete} data={selectedData} />
      <UpdateTopic open={isOpenUpdateTopic} close={handleCloseUpdateTopic} data={selectedTopic} calculators={calculators} />
      <DeleteTopic open={isOpenDeleteTopic} close={handleCloseDeleteTopic} data={selectedTopic} />
      <Col span={24}>
        <Row className="title-admin-page d-flex jc-sb ai-center">
          <Col>
            <h2 className="m-0">{guide.title}</h2>
          </Col>
          <Col>
            <Button size="large" onClick={handleOpenCreate}>
              Adicionar módulo
            </Button>
          </Col>
        </Row>
        <Spin spinning={isLoading}>
          <Row gutter={[0, 24]} className="m-0">
            {modules?.slice(minValue, maxValue).map((item) => {
              return (
                <Col span={24} key={item.id} className="p-0">
                  <Row gutter={[24]} className="dashboard-article-item m-0">
                    <Col span={19}>
                      <p className="f-11 mb-5">Título</p>
                      <p className="ellipsisThree bold f-18">{item.title}</p>
                      <Collapse
                        ghost
                        className="mt-40 w-100"
                        items={[
                          {
                            key: "1",
                            label: "Tópicos",
                            children: item.topics.map((t) => (
                              <Row className="pb-10 pt-10 w-100">
                                <Col span={20}>{t.title}</Col>
                                <Col span={4}>
                                  <Tooltip title="Editar">
                                    <EditOutlined key="edit" className="f-20 mr-20" onClick={() => handleOpenUpdateTopic(t)} />
                                  </Tooltip>
                                  <Tooltip title="Apagar">
                                    <DeleteOutlined key="delete" className="f-20" onClick={() => handleOpenDeleteTopic(t)} />
                                  </Tooltip>
                                </Col>
                              </Row>
                            )),
                          },
                        ]}
                      ></Collapse>
                    </Col>
                    <Col span={4}>
                      <p className="f-11 text-center mb-5">Posição</p>
                      <p className="f-22 text-center bold">{item.position}</p>
                    </Col>
                    <Col span={1} className="d-flex flex-column jc-center ai-center">
                      <Tooltip title="Editar">
                        <EditOutlined key="edit" className="f-20 mb-20" onClick={() => handleOpenUpdate(item)} />
                      </Tooltip>
                      <Tooltip title="Apagar">
                        <DeleteOutlined key="delete" className="f-20" onClick={() => handleOpenDelete(item)} />
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              );
            })}

            <Row className="ml-0 mr-0" align="center">
              <Pagination showSizeChanger={false} onChange={handleChangePage} pageSize={itemsPerPage} defaultCurrent={1} current={currentPage} total={modules.length} />
            </Row>
            {modules.length === 0 && (
              <div style={{ width: "100%", display: "block", margin: "0 auto" }}>
                <Empty description="Não foram encontrados guias" image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </Row>
        </Spin>
      </Col>
    </Row>
  );
};

export default DashboardGuideModules;
