import React, { useState } from "react";
import { Col, Row, Button, Form, Input, message } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

import endpoints from "../../../services/endpoints";
import GoogleReCAPTCHA from "../../../services/recaptcha";

import "./FormSuggestions.css";

const FormSuggestions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.suggestions.create, {
        data: values,
      })
      .then((res) => {
        setTimeout(() => {
          setIsButtonLoading(false);
          setFormSubmitted(true);
          form.resetFields();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
        message.error({
          content: "Aconteceu algum erro, por favor tente novamente mais tarde! ",
        });
      });
  }

  const [isVerified, setIsVerified] = useState(true);
  const handleVerify = (value) => {
    setIsVerified(!!value);
  };

  return (
    <>
      <Helmet>
        <title>Deixe aqui as suas sugestões para que consigamos melhorar o Cardio4all</title>
        <meta name="description" content="As suas sugestões vão nos ajudar a melhorar, contribua para a melhoria constante do Cardio4all." />
        <meta
          name="keywords"
          content="Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Deixe aqui as suas sugestões para que consigamos melhorar o Cardio4all" />
        <meta property="og:description" content="As suas sugestões vão nos ajudar a melhorar, contribua para a melhoria constante do Cardio4all." />
      </Helmet>
      <GoogleReCAPTCHA onVerify={handleVerify} />
      <Row className="h-100 w-100 pt-40 pb-40">
        <Col span={24} className="container d-flex flex-column jc-center ai-center">
          {!formSubmitted ? (
            <Row className="login-row">
              <Col span={24}>
                <h5 className="f-36 bold blue italic mb-50 text-center">Partilhe connosco as suas sugestões </h5>
                <Form className="suggestions-form d-flex flex-column jc-center ai-center mb-40" name="basic" onFinish={handleSubmit} form={form}>
                  <Row gutter={[16, 8]}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Nome" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            type: "email",
                            message: "Introduza um endereço de e-mail válido",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="E-mail" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="subject"
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Assunto" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="text"
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório",
                          },
                        ]}
                      >
                        <Input.TextArea size="large" placeholder="Mensagem" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[24]} className="mt-20">
                    <Col span={24} className="d-flex jc-center ai-center">
                      <Button loading={isButtonLoading} type="primary" htmlType="submit" className="submit-btn mr-10">
                        Enviar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          ) : (
            <Row className="login-row">
              <Col span={24} className="d-flex flex-column jc-center ai-center">
                <SmileOutlined className="mb-40 blue" style={{ fontSize: 100 }} />
                <h5 className="f-36 bold blue italic mb-60 text-center">
                  Obrigado por partilhar connosco as suas sugestões de melhoria elas serão tidas em conta para juntos melhorarmos o Cardio4all
                </h5>
                <Button loading={isButtonLoading} type="primary" onClick={() => setFormSubmitted(false)} className="new-suggestion-btn" disabled={!isVerified}>
                  Nova sugestão
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default FormSuggestions;
