import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

import endpoints from "../../services/endpoints";
import api from "../../services/api";

import "./MainLayout.css";

const AuthGuard = ({ user, isLoggedIn, handleLogin }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userIsValid, setUserIsValid] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get("token");
    if (token && !location.pathname.includes("reset-password")) {
      axios
        .get(endpoints.user.read, {
          params: { token },
        })
        .then((res) => {
          api.axiosInit(token).then((result) => {
            setUserIsValid(true);
            localStorage.setItem("token", token);
            handleLogin(res.data.token_decoded, location.pathname);
          });
        })
        .catch((err) => {
          console.log(err);
          setUserIsValid(false);
        });
    } else {
      if (!isLoggedIn) {
        return navigate(`/login?redirect=${location.pathname}${location.search}`);
      }
    }
  }, [location]);

  return <Outlet />;
};
export default AuthGuard;
