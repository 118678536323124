import React from "react";
import { Col, Row } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import BannerPodcasts from "../../../assets/images/podcasts-banner.png";
import BannerGuidelines from "../../../assets/images/resumos-guidelines-cardiovascular-banner.png";

import "./BannerVideocast.css";

export default function BannerVideocast({ data }) {
  return (
    <Row>
      <Col span={24} className="banner_home">
        <Swiper
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          id="banner_desktop"
        >
          <SwiperSlide className="slide_banner_desktop videocast">
            <Row
              className="banner_principal"
              style={{
                backgroundImage: `linear-gradient(90deg, #E1EDF8 100%, rgba(255,255,255,0) 100%)`,
              }}
            >
              <Row className="background_img_row">
                <Col xs={0} sm={0} md={0} lg={12} xl={12}></Col>
                <Col
                  className="background_img_col"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  style={{
                    backgroundImage: `url(${BannerPodcasts})`,
                  }}
                ></Col>
              </Row>
              <Col span={24} className="container content_banner">
                <Row gutter={[24]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <p className="blue bold title_slide_videocast">Podcasts de atualização científica</p>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}></Col>
                </Row>
              </Col>
            </Row>
          </SwiperSlide>

          <SwiperSlide className="slide_banner_desktop">
            <Row
              className="banner_principal"
              style={{
                backgroundImage: `linear-gradient(90deg, #0E426A 100%, rgba(255,255,255,0) 100%)`,
              }}
            >
              <Row className="background_img_row">
                <Col xs={0} sm={0} md={0} lg={12} xl={12}></Col>
                <Col
                  className="background_img_col"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  style={{
                    backgroundImage: `url(${BannerGuidelines})`,
                  }}
                ></Col>
              </Row>
              <Col span={24} className="container content_banner">
                <Row gutter={[24]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <p className="white bold title_slide_videocast">Guidelines Prevenção Cardiovascular</p>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}></Col>
                </Row>
              </Col>
            </Row>
          </SwiperSlide>
        </Swiper>
      </Col>
    </Row>
  );
}
