import React, { useState, useEffect } from "react";
import { Col, Row, Button, message, Table, Input, Form } from "antd";
import { EditOutlined, DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import axios from "axios";

import Import from "../../../components/dashboard/EventUser/Import";
import Create from "../../../components/dashboard/EventUser/Create";
import Update from "../../../components/dashboard/EventUser/Update";
import Delete from "../../../components/dashboard/EventUser/Delete";
import DrawerExportTable from "../../../components/dashboard/Export/DrawerExportTable";
import endpoints from "../../../services/endpoints";

import amarin from "../../../assets/images/event/amarin.svg";
import az from "../../../assets/images/event/az.svg";
import azevedos from "../../../assets/images/event/azevedos.svg";
import bayer from "../../../assets/images/event/bayer.svg";
import bial from "../../../assets/images/event/bial.svg";
import boe from "../../../assets/images/event/boe.svg";
import dai from "../../../assets/images/event/daiichi.svg";
import ferrer from "../../../assets/images/event/ferrer.svg";
import medinfar from "../../../assets/images/event/medinfar.svg";
import menarin from "../../../assets/images/event/menarin.svg";
import pfizer from "../../../assets/images/event/pfizer.svg";
import servier from "../../../assets/images/event/servier.svg";
import tecnimede from "../../../assets/images/event/tecnimede.svg";
import unilabs from "../../../assets/images/event/unilabs.svg";
import viatris from "../../../assets/images/event/viatris.svg";

import "../../../assets/styles/admin.css";

const DashboardEventsUsers = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenImport, setIsOpenImport] = useState(false);
  const [isOpenExportDrawer, setIsOpenExportDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonSendingLoading, setIsButtonSendingLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "E-mail",
      dataIndex: "email",
    },
    {
      title: "Tipo",
      dataIndex: "type",
      filters: [
        {
          text: "Virtual",
          value: "Virtual",
        },
        {
          text: "Presencial",
          value: "Presencial",
        },
      ],
      onFilter: (value, record) => {
        return record.full_data.type === value;
      },
    },
    {
      title: "Código",
      dataIndex: "code",
    },
    {
      title: "Convidado por",
      dataIndex: "invited_from",
      filters: [
        {
          text: <img src={amarin} className="maxh-20" />,
          value: "amarin",
        },
        {
          text: <img src={az} className="maxh-20" />,
          value: "az",
        },
        {
          text: <img src={azevedos} className="maxh-20" />,
          value: "azevedos",
        },
        {
          text: <img src={bayer} className="maxh-20" />,
          value: "bayer",
        },
        {
          text: <img src={bial} className="maxh-20" />,
          value: "bial",
        },
        {
          text: <img src={boe} className="maxh-20" />,
          value: "boe",
        },
        {
          text: <img src={dai} className="maxh-20" />,
          value: "dai",
        },
        {
          text: <img src={ferrer} className="maxh-20" />,
          value: "ferrer",
        },
        {
          text: <img src={medinfar} className="maxh-20" />,
          value: "medinfar",
        },
        {
          text: <img src={menarin} className="maxh-20" />,
          value: "menarin",
        },
        {
          text: <img src={pfizer} className="maxh-20" />,
          value: "pfizer",
        },
        {
          text: <img src={servier} className="maxh-20" />,
          value: "servier",
        },
        {
          text: <img src={tecnimede} className="maxh-20" />,
          value: "tecnimede",
        },
        {
          text: <img src={unilabs} className="maxh-20" />,
          value: "unilabs",
        },
        {
          text: <img src={viatris} className="maxh-20" />,
          value: "viatris",
        },
      ],
      onFilter: (value, record) => {
        return record.full_data.invited_from === value;
      },
    },
    {
      title: "",
      dataIndex: "actions",
    },
  ];

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.events.read)
      .then((res) => {
        setData(res.data);

        if (form.getFieldsValue().search) {
          handleSearch(form.getFieldsValue(), res.data);
        } else {
          handlePrepareData(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  function handlePrepareData(item) {
    setIsLoading(true);
    let aux = [];
    for (let i = 0; i < item.length; i++) {
      let auxObj = {
        id: item[i].id,
        name: item[i].name,
        email: item[i].email,
        type: item[i].type,
        code: item[i].password,
        invited_from: handleGetImage(item[i].invited_from),
        full_data: item[i],
        actions: (
          <div className="d-flex jc-end ai-center">
            <Button className="mr-10" onClick={() => handleSendCode(item[i])}>
              Enviar código
            </Button>
            <Button className="mr-10" onClick={() => handleOpenUpdate(item[i])}>
              <EditOutlined />
            </Button>
            <Button danger onClick={() => handleOpenDelete(item[i])}>
              <DeleteOutlined />
            </Button>
          </div>
        ),
      };

      aux.push(auxObj);
    }

    setIsLoading(false);
    setTableData(aux);
  }

  function handleGetImage(invited_from) {
    switch (invited_from) {
      case "amarin":
        return <img className="maxh-20" src={amarin} />;
      case "az":
        return <img className="maxh-20" src={az} />;
      case "azevedos":
        return <img className="maxh-20" src={azevedos} />;
      case "bayer":
        return <img className="maxh-20" src={bayer} />;
      case "bial":
        return <img className="maxh-20" src={bial} />;
      case "boe":
        return <img className="maxh-20" src={boe} />;
      case "dai":
        return <img className="maxh-20" src={dai} />;
      case "ferrer":
        return <img className="maxh-20" src={ferrer} />;
      case "medinfar":
        return <img className="maxh-20" src={medinfar} />;
      case "menarin":
        return <img className="maxh-20" src={menarin} />;
      case "pfizer":
        return <img className="maxh-20" src={pfizer} />;
      case "servier":
        return <img className="maxh-20" src={servier} />;
      case "tecnimede":
        return <img className="maxh-20" src={tecnimede} />;
      case "unilabs":
        return <img className="maxh-20" src={unilabs} />;
      case "viatris":
        return <img className="maxh-20" src={viatris} />;
      default:
        return "ND";
    }
  }

  function handleSendCode(item) {
    messageApi.open({
      key: "send-code",
      type: "loading",
      content: "A enviar código para o participante...",
    });
    axios
      .get(endpoints.events.sendCode, {
        params: { email: item.email },
      })
      .then((res) => {
        messageApi.open({
          key: "send-code",
          type: "success",
          content: "Código enviado com sucesso!",
          duration: 2,
        });
        setIsButtonSendingLoading(false);
      })
      .catch((err) => {
        console.log(err);
        messageApi.open({
          key: "send-code",
          type: "error",
          content: err.response.data.message,
          duration: 2,
        });
        setIsButtonSendingLoading(false);
      });
  }

  function handleOpenImport() {
    setIsOpenImport(true);
  }

  function handleCloseImport() {
    setIsOpenImport(false);
    handleGetData();
  }

  function handleSearch(e, _, auxData) {
    console.log(auxData);
    let searchString = e.search.toLowerCase();
    let allData = auxData ? auxData : data;
    let filterData = allData.filter((item) => item.email.includes(searchString) || item.name.toLowerCase().includes(searchString));
    handlePrepareData(filterData);
  }

  function handleOpenExport() {
    setIsOpenExportDrawer(true);
  }

  function handleCloseExport() {
    setIsOpenExportDrawer(false);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(item) {
    setSelectedData(item);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setSelectedData({});
    setIsOpenUpdate(false);
    handleGetData();
  }
  function handleOpenDelete(item) {
    setSelectedData(item);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetData();
  }

  return (
    <Row>
      {contextHolder}
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Import open={isOpenImport} close={handleCloseImport} />
      <DrawerExportTable open={isOpenExportDrawer} close={handleCloseExport} data={tableData} />
      <Col span={24}>
        <Row className="title-admin-page d-flex jc-sb ai-center">
          <Col span={6}>
            <h2 className="m-0">Participantes</h2>
          </Col>
          <Col span={18}>
            <div className="d-flex jc-end ai-center">
              <Form form={form} onValuesChange={handleSearch} style={{ maxWidth: 400, borderRadius: 0 }}>
                <Form.Item name="search" className="mb-0 mr-10">
                  <Input size="large" className="" style={{ maxWidth: 400, borderRadius: 0 }} placeholder="Procurar..." allowClear />
                </Form.Item>
              </Form>
              <Button className="mr-10" size="large" onClick={handleGetData}>
                <ReloadOutlined />
              </Button>
              <Button className="mr-10" size="large" onClick={handleOpenExport}>
                Exportar
              </Button>
              <Button className="mr-10" size="large" onClick={handleOpenImport}>
                Importar
              </Button>
              <Button className="mr-10" size="large" onClick={handleOpenCreate}>
                Adicionar
              </Button>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Table loading={isLoading} columns={columns} dataSource={tableData} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DashboardEventsUsers;
