import React from "react";
import { Col, Row, Card, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import dayjs from "dayjs";

import config from "../../../services/config";

import "./GridArticles.css";

function GridArticles({ data }) {
  const navigate = useNavigate();

  return (
    <Row className="container">
      <Col span={24} className="mt-80">
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          className="mySwiper d-flex jc-start ai-start"
        >
          {data.map((card, index) => {
            const today = dayjs();
            const articleDate = dayjs(card.date);
            return (
              <SwiperSlide key={"swiper-card-" + card.id} id={card.id} className="swiper-card p-20">
                <Card
                  cover={
                    <div
                      className="article-card-cover"
                      style={{
                        backgroundImage: `url(${config.server_ip}/assets/media/${card.image_thumbnail})`,
                      }}
                    ></div>
                  }
                  className="article-grid-card"
                >
                  {today.diff(articleDate, "day") < 15 && <span className="novo-artigo-span">NOVO ARTIGO</span>}
                  <div>
                    <h2 className="f-26 blue mb-10 ellipsisTwo">{card.title}</h2>
                    <p className="f-16 mb-10 ellipsisThree">{card.description}</p>
                  </div>
                  <div>
                    <Link to={`/artigos/${card.slug}`}>
                      <Button size="large" className="btn-red mt-40">
                        Ler artigo completo
                      </Button>
                    </Link>
                  </div>
                </Card>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Col>
    </Row>
  );
}

export default GridArticles;
