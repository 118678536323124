import React, { useState } from "react";
import { Button, Col, Row, Modal } from "antd";
import axios from "axios";

import endpoints from "../../../services/endpoints";

import "react-quill/dist/quill.snow.css";
import "../../../assets/styles/admin.css";

function Update({ open, close, data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  function handleClose() {
    close();
  }

  function handleSubmit() {
    setIsButtonLoading(true);
    axios
      .post(endpoints.guides.deleteModule, {
        data: data.id,
      })
      .then((res) => {
        setTimeout(() => {
          setIsButtonLoading(false);
          handleClose();
        }, 1000);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Modal
      className="modal-delete-guide p-20"
      key={`modal-delete-guide-${data.id}`}
      title="Apagar guia"
      width={800}
      onCancel={handleClose}
      open={open}
      footer={
        <>
          <Button disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
            Cancelar
          </Button>
          <Button loading={isButtonLoading} type="primary" onClick={handleSubmit}>
            Apagar
          </Button>
        </>
      }
    >
      <Row>
        <Col span={24}>
          <h3>Deseja mesmo apagar o guia:</h3>
          <p className="f-26">{data.title}</p>
        </Col>
      </Row>
    </Modal>
  );
}

export default Update;
