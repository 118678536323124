import React, { useState, useEffect } from "react";
import { Col, Row, Button, Table } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";

import endpoints from "../../../services/endpoints";
import Create from "../../../components/dashboard/tag/Create";
import Update from "../../../components/dashboard/tag/Update";
import Delete from "../../../components/dashboard/tag/Delete";

import "../../../assets/styles/admin.css";

export default function DashboardTags() {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(20);

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      width: 100,
    },
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "",
      dataIndex: "actions",
    },
  ];

  useEffect(() => {
    handleGetBanners();
  }, []);

  function handleGetBanners() {
    axios
      .get(endpoints.tags.read)
      .then((res) => {
        setData(res.data);
        handlePrepareData(res.data);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }

  function handlePrepareData(data) {
    let newData = [];
    data.forEach((element, index) => {
      newData.push({
        key: index + 1,
        name: element.name,
        actions: (
          <div className="d-flex jc-end">
            <EditOutlined className="mr-10" key="edit" onClick={() => handleOpenUpdate(element)} />
            <DeleteOutlined key="delete" onClick={() => handleOpenDelete(element)} />
          </div>
        ),
      });
    });

    setTableData(newData);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetBanners();
  }

  function handleOpenUpdate(data) {
    setSelectedTag(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetBanners();
  }

  function handleOpenDelete(data) {
    setSelectedTag(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedTag({});
    setIsOpenDelete(false);
    handleGetBanners();
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  return (
    <Row>
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update data={selectedTag} open={isOpenUpdate} close={handleCloseUpdate} />
      <Delete data={selectedTag} open={isOpenDelete} close={handleCloseDelete} />
      <Col span={24}>
        <Row className="title-admin-page d-flex jc-sb ai-center">
          <Col>
            <h2 className="m-0">Tags</h2>
          </Col>
          <Col>
            <Button size="large" onClick={handleOpenCreate}>
              Adicionar
            </Button>
          </Col>
        </Row>
        <Table loading={isLoading} columns={columns} dataSource={tableData} />
      </Col>
    </Row>
  );
}
