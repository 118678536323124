import React, { useState, useEffect } from "react";
import { Col, Row, Button, Empty, Pagination, Tooltip, Spin, Image } from "antd";
import { EditOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import axios from "axios";

import Update from "../../../components/dashboard/ClinicalCase/Update";
import Create from "../../../components/dashboard/ClinicalCase/Create";
import Delete from "../../../components/dashboard/ClinicalCase/Delete";
import Questions from "../../../components/dashboard/ClinicalCase/Questions";
import Orientations from "../../../components/dashboard/ClinicalCase/Orientations";
import endpoints from "../../../services/endpoints";
import config from "../../../services/config";

import "./ClinicalCases.css";
import "../../../assets/styles/admin.css";

const DashboardClinicalCases = () => {
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenQuestions, setIsOpenQuestions] = useState(false);
  const [isOpenOrientations, setIsOpenOrientations] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(8);

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    axios
      .get(endpoints.clinicalCases.read)
      .then((res) => {
        let auxClinicalCases = res.data[0];
        let auxClinicalCasesQuestions = res.data[1];
        let newClinicalCases = [];
        for (let i = 0; i < auxClinicalCases.length; i++) {
          let findQuestions = auxClinicalCasesQuestions.filter((item) => item.id_clinical_case === auxClinicalCases[i].id);
          if (findQuestions.length > 0) {
            for (let z = 0; z < findQuestions.length; z++) {
              findQuestions[z].answers = JSON.parse(findQuestions[z].answers);
            }
          }

          auxClinicalCases[i].patient_info = JSON.parse(auxClinicalCases[i].patient_info);
          newClinicalCases.push({
            ...auxClinicalCases[i],
            questions: findQuestions,
          });
        }

        setData(newClinicalCases);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(item) {
    setSelectedData(item);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(item) {
    setSelectedData(item);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetData();
  }

  function handleOpenQuestions(item) {
    setSelectedData(item);
    setIsOpenQuestions(true);
  }

  function handleOpenOrientations(item) {
    setSelectedData(item);
    setIsOpenOrientations(true);
  }

  function handleCloseQuestions() {
    setIsOpenQuestions(false);
    handleGetData();
  }

  function handleCloseOrientations() {
    setIsOpenOrientations(false);
    handleGetData();
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <Row>
        <Create open={isOpenCreate} close={handleCloseCreate} />
        <Orientations open={isOpenOrientations} close={handleCloseOrientations} data={selectedData} />
        <Questions open={isOpenQuestions} close={handleCloseQuestions} data={selectedData} />
        <Update open={isOpenUpdate} close={handleCloseUpdate} data={selectedData} />
        <Delete open={isOpenDelete} close={handleCloseDelete} data={selectedData} />
        <Col span={24}>
          <Row className="title-admin-page d-flex jc-sb ai-center">
            <Col>
              <h2 className="m-0">Casos clínicos</h2>
            </Col>
            <Col>
              <Button size="large" onClick={handleOpenCreate}>
                Adicionar
              </Button>
            </Col>
          </Row>
          <Row gutter={[0, 24]} className="m-0">
            {data.length === 0 && (
              <div style={{ width: "100%", display: "block", margin: "0 auto" }}>
                <Empty description="Não foram encontrados guias" image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
            {data?.slice(minValue, maxValue).map((item) => {
              return (
                <Col span={24} key={item.id} className="p-0">
                  <Row gutter={[24]} className="dashboard-clinical-cases-item m-0">
                    <Col span={1} className="d-flex flex-column jc-center ai-center">
                      <p className="f-22 text-center bold">{item.position}</p>
                    </Col>
                    <Col span={9}>
                      <p className="bold">{item.name}</p>
                      <div className="d-flex ai-center">
                        <div>
                          <img src={`${config.server_ip}/assets/media/${item.image}`} className="w-100 maxw-80 mt-20" />
                        </div>
                        <div className="ml-20">
                          <p className="bold">{item.title}</p>
                          <p>{item.text}</p>
                        </div>
                      </div>
                      <p className="f-11 mt-20">Data de publicação</p>
                      <p>{dayjs(item.publish_date).format("DD-MM-YYYY HH:mm")}</p>
                      <p className="f-11 mt-20">Descrição</p>
                      <div className="description" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                    </Col>
                    <Col span={10}>
                      <p className="f-11">Informação do paciente</p>
                      <Row gutter={[24]} className="w-100">
                        {item.patient_info.map((info) => (
                          <Col span={24} className="mb-10">
                            <div className="patient-info-dashboard h-100">
                              <p className="f-14 bold">{info.title}</p>
                              <div className="text" dangerouslySetInnerHTML={{ __html: info.text }}></div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    <Col span={3}>
                      <p className="f-11 mt-20">Electrocardiograma</p>
                      <Image src={`${config.server_ip}/assets/media/${item.electrocardiogram}`} className="w-100 mt-5" />
                      <p className="f-11 mt-20">Estudo analítico</p>
                      <Image src={`${config.server_ip}/assets/media/${item.analytical_study}`} className="w-100 mt-5" />
                      <p className="f-12 m-t5">{item.analytical_study_subtitle}</p>
                    </Col>
                    <Col span={1} className="d-flex flex-column jc-center ai-center">
                      <Tooltip title="Orientações">
                        <QuestionCircleOutlined key="edit" className="f-20 mb-20" onClick={() => handleOpenOrientations(item)} />
                      </Tooltip>
                      <Tooltip title="Perguntas">
                        <QuestionCircleOutlined key="edit" className="f-20 mb-20" onClick={() => handleOpenQuestions(item)} />
                      </Tooltip>
                      <Tooltip title="Editar">
                        <EditOutlined key="edit" className="f-20 mb-20" onClick={() => handleOpenUpdate(item)} />
                      </Tooltip>
                      <Tooltip title="Apagar">
                        <DeleteOutlined key="delete" className="f-20" onClick={() => handleOpenDelete(item)} />
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              );
            })}

            <Row className="ml-0 mr-0" align="center">
              <Pagination showSizeChanger={false} onChange={handleChangePage} pageSize={itemsPerPage} defaultCurrent={1} current={currentPage} total={data.length} />
            </Row>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default DashboardClinicalCases;
