import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import "../../../assets/styles/calculator.css";

export default function CalculatorBanner(props) {
  return (
    <>
      <Row className="banner">
        <Col className="container">
          <Row align={"middle"}>
            <Col span={24}>
              <h1 className="banner__title">
                <b>Calculadora</b> <br />
                {props.title}
              </h1>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="container mt-10">
        <Link to="/calculadoras" className="d-flex flex-column">
          <p style={{ color: "#85B8E3" }} className="f-16 bold">
            Voltar a calculadoras
          </p>
          <span className="d-flex ai-center jc-center mt-10">
            <ArrowLeftOutlined style={{ color: "#85B8E3" }} />
          </span>
        </Link>
      </Row>
    </>
  );
}
