import React, { useState } from "react";
import { Col, Form, Row, Radio } from "antd";
import { Helmet } from "react-helmet";

import CalculatorBanner from "../../../components/website/CalculatorBanner/CalculatorBanner";
import { CalculatorLinks } from "../../../components/website/CalculatorLinks/CalculatorLinks";
import { CalculatorResult } from "../../../components/website/CalculatorResult/CalculatorResult";
import { CalculatorContent } from "../../../components/website/CalculatorContent/CalculatorContent";

import "../../../assets/styles/calculator.css";

/* CALCULADORA SCORE HASBLED (RISCO HEMORRÁGICO) */
function CalculatorHASBLED() {
  const [risks] = useState([0.2, 0.6, 2.2, 3.2, 4.8, 7.2, 9.7, 10.8, 11.2, 12.2]);
  const [points, setPoints] = useState(0);
  const [message, setMessage] = useState("");
  const [treatment, setTreatment] = useState("");
  const [showResult, setShowResult] = useState(false);

  const [form] = Form.useForm();

  function handleSubmit(changedFields, allFields) {
    let columns = Object.keys(allFields);
    let sumPoints = 0;
    let readyToCalc = true;

    for (let i = 0; i < columns.length; i++) {
      if (allFields[columns[i]] !== null && allFields[columns[i]] !== undefined) {
        sumPoints += allFields[columns[i]];
      } else {
        readyToCalc = false;
        return;
      }
    }

    let auxMessage = "";
    let auxTreatment = "";

    if (readyToCalc) {
      if (sumPoints < 3) {
        auxMessage = "Risco relativamente baixo de hemorragia grave";
      } else {
        auxMessage = "Risco hemorrágico significativo";
        auxTreatment = "<p class='mt-30'>A hipocoagulação não está contra-indicada mas são recomendadas as seguintes medidas de redução do risco hemorrágico:</p>";
        auxTreatment += "<ul class='mt-10 pl-30'><li>Melhoria do controlo da pressão arterial</li>";
        auxTreatment += "<li>Cessação ingestão de álcool</li>";
        auxTreatment += "<li>Evitar a ingestão simultânea de AINEs, corticoesteróides, anti-plaquetários</li>";
        auxTreatment += "<li>Ajustar a dose de hipocoagulação à função renal</li>";
        auxTreatment += "<li>Considerar a utilização profilática de inibidor da bomba de protões</li></ul>";
      }

      setMessage(auxMessage);
      setTreatment(auxTreatment);
      setPoints(sumPoints);
      setShowResult(true);
    }
  }

  return (
    <>
      <Helmet>
        <title>Calculadora SCORE HASBLED (RISCO HEMORRÁGICO) | Formação Científica em Medicina Cardiovascular</title>
        <meta
          name="description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta
          name="keywords"
          content="calculadoras área cardiovascular, Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Calculadora SCORE HASBLED (RISCO HEMORRÁGICO) | Formação Científica em Medicina Cardiovascular" />
        <meta
          property="og:description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
      </Helmet>
      <CalculatorBanner title="SCORE HASBLED (RISCO HEMORRÁGICO)" />
      <CalculatorContent>
        <Form form={form} onValuesChange={handleSubmit} className="form_calculator">
          {/* Row #1 */}
          <Row
            gutter={[
              { xs: 20, sm: 40, md: 40, lg: 60 },
              { xs: 40, sm: 40, md: 40, lg: 60 },
            ]}
            className="m-0"
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Hipertensão arterial</p>
              <Form.Item name="hypertension" className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10">{"Não controlada > 160mmHg sistólica"}</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Doença renal</p>
              <Form.Item name="renal" className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10">{"Diálise, transplante, Cr > 2,26 mg/dL"}</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Doença Hepática</p>
              <Form.Item name="hepatic" className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10">{"Cirrose ou bilirrubina > 2x normal com AST/ALT/AP > 3x normal"}</p>
            </Col>

            {/* Row #2 */}
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Antecedentes de AVC</p>
              <Form.Item name="avc" className="mt-40 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Antecedentes de hemorragia major ou predisposição para hemorragia</p>
              <Form.Item name="bleeding" className="mt-20 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">INR lábil</p>
              <Form.Item name="inr" className="mt-40 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10">{"INRs instáveis / altos, tempo na faixa terapêutica < 60%"}</p>
            </Col>

            {/* Row #3 */}
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">{"Idade > 65 anos"}</p>
              <Form.Item name="age" className="mt-40 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Uso de medicamentos com predisposição Hemorrágica</p>
              <Form.Item name="meds" className="mt-20 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10">Aspirina, Clopidogrel, NSAIDs</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Consumo de álcool</p>
              <Form.Item name={"alcohol"} className="mt-40 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10">{"> 8 bebidas / semana"}</p>
            </Col>
          </Row>
        </Form>
      </CalculatorContent>

      {/* TODO: colocarmos condicao para aparecer o bloco com resultado*/}
      {showResult && (
        <CalculatorResult className="HASBLED">
          <Row gutter={[24]} className="m-0">
            <Col xs={24} sm={24} md={24} lg={24} xl={8} className="mt-15">
              <p className="white">Resultado:</p>
              <p className="f-50 bold white mobile-f-40">{points} pontos</p>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={16} className="d-flex flex-column jc-center ai-start calculator_treatment p-20 mt-15 mb-15">
              <p className="f-20 white">{message}</p>
              <div dangerouslySetInnerHTML={{ __html: treatment }}></div>
            </Col>
          </Row>
        </CalculatorResult>
      )}

      <CalculatorLinks />
    </>
  );
}
export default CalculatorHASBLED;
