import React, { useEffect, useState } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";

import { Col, Row } from "antd";
import { Link } from "react-router-dom";

import config from "../../../services/config";

import "../../../assets/styles/articles.css";
import "./ListGuiasPraticos.css";

const ListGuiasPraticos = ({ data }) => {
  const [guides, setGuides] = useState(data);
  const [guidesToShow, setGuidesToShow] = useState(guides);

  useEffect(() => {
    setGuides(data);
    setGuidesToShow(data);
  }, [data]);

  return (
    <Row className="container mb-40">
      <Col span={24} className="d-flex flex-column jc-center ai-center">
        <Row gutter={[24, 24]} className="jc-start mt-40">
          {guidesToShow.map((item, index) => {
            if (item != undefined) {
              return (
                <Col xs={24} sm={24} md={24} lg={12} xl={12} key={"guide-" + index}>
                  <Link to={`/guias-praticos/${item?.slug}`}>
                    <div className="list-guide-item">
                      <div
                        className="image"
                        style={{
                          backgroundImage: `url(${config.server_ip}/assets/media/${item?.image})`,
                        }}
                      ></div>
                      <div className="d-flex jc-sb w-100 p-20 flex-wrap">
                        <div className="d-flex flex-column jc-center" style={{ maxWidth: 300 }}>
                          <p className="f-18 blue ellipsisTwo">{item?.title}</p>
                        </div>
                        <div className="minw-100 d-flex flex-column jc-center ai-center link">
                          <p>Ver mais</p>
                          <span>
                            <ArrowRightOutlined />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
              );
            }
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default ListGuiasPraticos;
