import React, { useState } from "react";
import { Col, Form, Row, Radio, Button, Input, Tooltip } from "antd";
import { Helmet } from "react-helmet";

import CalculatorBanner from "../../../components/website/CalculatorBanner/CalculatorBanner";
import { CalculatorLinks } from "../../../components/website/CalculatorLinks/CalculatorLinks";
import { CalculatorResult } from "../../../components/website/CalculatorResult/CalculatorResult";
import { CalculatorContent } from "../../../components/website/CalculatorContent/CalculatorContent";

import "../../../assets/styles/calculator.css";

/* CALCULADORA CLEARANCE DA CREATININA */
const CalculatorClearanceCreatinine = () => {
  const [points, setPoints] = useState(0);
  const [minValues] = useState({ age: 18, weight: 40, creatinine: 0.3 });
  const [maxValues] = useState({ age: 99, weight: 150, creatinine: 6.0 });
  const [isResultLoading, setIsResultLoading] = useState(false);
  const [errorFinded, setErrorFinded] = useState(null);

  const [form] = Form.useForm();

  function handleDebounce(func, timeout = 1200) {
    let timer;
    return (...args) => {
      setIsResultLoading(true);
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  function handleValuesChange() {
    let allValues = form.getFieldsValue();
    let columns = Object.keys(allValues);

    let nullItems = 0;
    let auxErrorsFinded = 0;

    for (let i = 0; i < columns.length; i++) {
      if (typeof allValues[columns[i]] === "string") {
        allValues[columns[i]] = Number(allValues[columns[i]]);
      }

      if (allValues[columns[i]] === null || allValues[columns[i]] === undefined) {
        nullItems++;
      }
    }

    if (nullItems === 0) {
      const validationErrors = Object.values(form.getFieldsError());

      for (let e = 0; e < validationErrors.length; e++) {
        if (validationErrors[e].errors.length > 0) {
          auxErrorsFinded++;
        }
      }
    }

    let sumPoints = 0;

    if (nullItems === 0 && auxErrorsFinded === 0) {
      sumPoints = ((140 - allValues.age) * allValues.weight) / (allValues.creatinine * 72);
      if (allValues.sex === 1) {
        sumPoints = sumPoints * 0.85;
      }

      setPoints(parseFloat(sumPoints).toFixed(0));
      setErrorFinded(false);
    } else {
      if (auxErrorsFinded > 0) {
        setErrorFinded(true);
      }
      setPoints(0);
    }
  }

  function handleIncrement(key) {
    let value = form.getFieldValue(key);
    if (typeof value === "string") {
      value = Number(value);
    }

    if (value) {
      if (value >= minValues[key] && value < maxValues[key]) {
        if (key === "creatinine") {
          value = value + 0.01;
          value = parseFloat(value).toFixed(2);
        } else {
          value++;
        }
      } else if (value < minValues[key]) {
        value = minValues[key];
      } else {
        value = maxValues[key];
      }

      form.setFieldValue([key], Number(value));
      form.validateFields([key]);
    } else {
      form.setFieldValue([key], minValues[key]);
      form.validateFields([key]);
    }
  }

  function handleDecrement(key) {
    let value = form.getFieldValue(key);
    if (typeof value === "string") {
      value = Number(value);
    }

    if (value) {
      if (value > minValues[key] && value <= maxValues[key]) {
        if (key === "creatinine") {
          value = value - 0.01;
          value = parseFloat(value).toFixed(2);
        } else {
          value--;
        }
      } else if (value <= minValues[key]) {
        value = minValues[key];
      } else {
        value = maxValues[key];
      }

      form.setFieldValue([key], Number(value));
      form.validateFields([key]);
    } else {
      form.setFieldValue([key], minValues[key]);
      form.validateFields([key]);
    }
  }

  function handleValidate(rule, value) {
    let key = rule.field;
    let keysOfNumber = Object.keys(minValues);
    if (keysOfNumber.includes(key)) {
      if (Number(value) < minValues[rule.field]) {
        return Promise.reject(<Tooltip color="red" placement="bottom" open={true} title={`Este valor não pode ser menor que ${minValues[rule.field]}`}></Tooltip>); // The validator should always return a promise on both success and error
      } else if (Number(value) > maxValues[rule.field]) {
        return Promise.reject(<Tooltip color="red" placement="bottom" open={true} title={`Este valor não pode ser maior que ${maxValues[rule.field]}`}></Tooltip>); // The validator should always return a promise on both success and error
      } else {
        return Promise.resolve(); // The validator should always return a promise on both success and error
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Calculadora CLEARANCE DA CREATININA | Formação Científica em Medicina Cardiovascular</title>
        <meta
          name="description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta
          name="keywords"
          content="calculadoras área cardiovascular, Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Calculadora CLEARANCE DA CREATININA | Formação Científica em Medicina Cardiovascular" />
        <meta
          property="og:description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
      </Helmet>
      <CalculatorBanner title="CLEARANCE DA CREATININA" />
      <CalculatorContent>
        <Form form={form} onFieldsChange={handleValuesChange} className="form_calculator">
          <Row
            gutter={[
              { xs: 20, sm: 8, md: 16, lg: 60 },
              { xs: 40, sm: 8, md: 16, lg: 60 },
            ]}
            className="mb-40"
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Sexo</p>
              <Form.Item name="sex" className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Feminino
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Masculino
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row
            gutter={[
              { xs: 20, sm: 8, md: 16, lg: 60 },
              { xs: 40, sm: 8, md: 16, lg: 60 },
            ]}
            className="mb-40"
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Idade</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("age")} className="calculator_button mr-10">
                  -
                </Button>
                <Form.Item name={"age"} className="w-100 mb-0" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("age")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Peso (kg)</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("weight")} className="calculator_button mr-10">
                  -
                </Button>
                <Form.Item name={"weight"} className="w-100 mb-0" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("weight")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Creatina sérica (ml/min)</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("creatinine")} className="calculator_button mr-10">
                  -
                </Button>
                <Form.Item name={"creatinine"} className="w-100 mb-0" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("creatinine")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </CalculatorContent>

      {points ? (
        <CalculatorResult isLoading={isResultLoading}>
          <Row gutter={[24]} className="d-flex jc-center ai-center">
            <Col span={24} className="d-flex flex-column jc-center">
              <p className="white">Resultado:</p>
              <p className="f-30 bold white">{points} mg/min</p>
            </Col>
          </Row>
        </CalculatorResult>
      ) : null}

      {errorFinded && (
        <CalculatorResult isLoading={isResultLoading}>
          <Row gutter={[24, 24]} className="h-100 ai-center jc-center">
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className="d-flex jc-center ai-center">
              <p className="f-20 text-center">Algum valor não está entre os parâmetros requeridos</p>
            </Col>
          </Row>
        </CalculatorResult>
      )}

      <CalculatorLinks />
    </>
  );
};
export default CalculatorClearanceCreatinine;
