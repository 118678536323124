import React, { useState, useEffect } from "react";
import { Col, Row, Button, Input, Form, notification } from "antd";
import axios from "axios";

import endpoints from "../../../services/endpoints";

import "../../../assets/styles/admin.css";

const Chat = ({ data, handleGetData }) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (data.length > 0) {
      let chatData = data.filter((item) => item.name === "chat")[0];
      form.setFieldsValue({ ...JSON.parse(chatData.json), id: chatData.id });
    }
  }, [data]);

  function handleSubmit(values) {
    setIsButtonLoading(true);
    let whereId = values.id;
    delete values.id;
    axios
      .post(endpoints.settings.update, {
        data: { id: whereId, values: values },
      })
      .then((res) => {
        handleGetData();
        setIsButtonLoading(false);
        notification.success({
          message: "Sucesso!",
          description: "Definições atualizadas com sucesso",
        });
      })
      .catch((error) => {
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu, por favor tente novamente.",
        });
        console.log(error);
      });
  }

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <div className="p-20">
              <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Form.Item name="id" hidden>
                  <Input />
                </Form.Item>
                <Form.Item label="Chat script link" name="link">
                  <Input size="large" placeholder="Procurar..." />
                </Form.Item>
              </Form>
              <Button type="primary" size="large" onClick={form.submit} loading={isButtonLoading}>
                Guardar
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Chat;
