import React, { useEffect, useState } from "react";
import { Col, Row, Button, Form, Input, Spin, message, Select, DatePicker, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { Helmet } from "react-helmet";

import GoogleReCAPTCHA from "../../../services/recaptcha";
import ModalDeleteAccount from "../../../components/website/ModalDeleteAccount/ModalDeleteAccount";
import districts from "../../../services/districts";
import endpoints from "../../../services/endpoints";

import ImageCoverBg from "../../../assets/images/bg-perfil@2x.png";

import "./Profile.css";

const Profile = ({ updateUser }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isOpenDeleteAccount, setIsOpenDeleteAccount] = useState(false);
  const [user, setUser] = useState({});
  const [counties, setCounties] = useState([]);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const [isVerified, setIsVerified] = useState(true);
  const handleVerify = (value) => {
    setIsVerified(!!value);
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      axios
        .get(endpoints.user.read, {
          params: { token },
        })
        .then((res) => {
          let auxUser = res.data.token_decoded;

          auxUser.birth_date = dayjs(auxUser.birth_date);

          if (res.data.update_token) {
            localStorage.setItem("token", res.data.token);
          }

          form.setFieldsValue(auxUser);
          setUser(auxUser);
          setIsLoading(false);
          if (auxUser.work_district) {
            handleChangeDistrict(auxUser.work_district);
          }
        })
        .catch((err) => {
          console.log(err);
          navigate("/login");
        });
    } else {
      navigate("/login");
    }
  }, []);

  function handleSubmit(values) {
    setIsButtonLoading(true);
    if (!values.new_confirm_password) {
      delete values.old_password;
      delete values.new_password;
      delete values.confirm_new_password;
    }

    axios
      .post(endpoints.user.update, {
        data: values,
      })
      .then((res) => {
        if (res.data.user_updated) {
          setUser(res.data.user);
          localStorage.setItem("token", res.data.token);
          message.success({ content: "O seu perfil foi editado com sucesso!" });
          setIsButtonLoading(false);
        } else {
          message.error({ content: res.data.message });
          setIsButtonLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
      });
  }

  function handleChangeDistrict(e) {
    let auxCounties = districts.filter((item) => item.name === e)[0];
    if (auxCounties) {
      setCounties(auxCounties.county);
    } else {
      form.setFieldValue("work_county", null);
    }
  }

  function handleDeleteAccount() {
    setIsOpenDeleteAccount(true);
  }

  function handleCloseDeleteAccount(is_deleted) {
    setIsOpenDeleteAccount(false);
    if (is_deleted) {
      localStorage.removeItem("token");
      navigate("/");
    }
  }

  return (
    <Spin spinning={isLoading}>
      <Helmet>
        <title>Perfil | Cardio4all | Formação Científica em Medicina Cardiovascular</title>
        <meta
          name="description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta
          name="keywords"
          content="Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Cardio4all | Formação Científica em Medicina Cardiovascular" />
        <meta
          property="og:description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <GoogleReCAPTCHA onVerify={handleVerify} />
      <Row className="h-100 w-100 login_content" style={{ minHeight: "100vh", backgroundImage: `url(${ImageCoverBg})` }}>
        <Col span={24} className="container d-flex flex-column jc-center ai-center">
          <Row className="login-row mt-20">
            <Col xs={24} sm={24} md={24} lg={18} xl={18}>
              <ModalDeleteAccount user={user} open={isOpenDeleteAccount} close={handleCloseDeleteAccount} />

              <Row gutter={[24]} className="ml-0 mr-0">
                <Col span={24}>
                  <p className="f-20 normal blue mb-30">
                    <b>Olá {user.name}!</b>
                    <br />
                    Atualize aqui todas as informações do seu perfil.
                  </p>
                </Col>
              </Row>
              <Form layout="vertical" className="profile-form" name="basic" onFinish={handleSubmit} form={form}>
                <Form.Item hidden name="id">
                  <Input />
                </Form.Item>
                <Row gutter={[24]} className="ml-0 mr-0">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      label="E-mail"
                      name="email"
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: "Introduza um endereço de e-mail válido",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="E-mail" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      label="Nome"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Este campo é obrigatório",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Nome" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24]} className="ml-0 mr-0 mt-15 mb-20">
                  <Col span={24}>
                    <p className="f-16 blue bold">Dados pessoais</p>
                  </Col>
                </Row>
                <Row gutter={[24]} className="ml-0 mr-0">
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label="Data de nascimento"
                      name="birth_date"
                      rules={[
                        {
                          required: true,
                          message: "Introduza a sua data de nascimento",
                        },
                      ]}
                    >
                      <DatePicker size="large" placeholder="Data de nascimento" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label="Número Ordem dos Médicos"
                      name="order_nr"
                      rules={[
                        {
                          required: true,
                          message: "Introduza o seu número de ordem",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Nº Ordem dos Médicos" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label="Especialidade"
                      name="specialty"
                      rules={[
                        {
                          required: true,
                          message: "Introduza a sua especialidade",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        showSearch
                        placeholder="Profissão"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        options={[
                          {
                            value: "Cardiologia",
                            label: "Cardiologia",
                          },
                          {
                            value: "MGF",
                            label: "MGF",
                          },
                          {
                            value: "Medicina Interna",
                            label: "Medicina Interna",
                          },
                          {
                            value: "Outra",
                            label: "Outra",
                          },
                        ]}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[24]} className="ml-0 mr-0 mb-20 mt-15">
                  <Col span={24}>
                    <p className="f-16 blue bold">Local de trabalho</p>
                  </Col>
                </Row>
                <Row gutter={[24]} className="ml-0 mr-0">
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item label="Distrito" name="work_district">
                      <Select
                        size="large"
                        showSearch
                        placeholder="Distrito"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        onChange={handleChangeDistrict}
                        options={districts.map((item) => ({
                          value: item.name,
                          label: item.name,
                        }))}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item label="Concelho" name="work_county">
                      <Select
                        size="large"
                        showSearch
                        placeholder="Concelho"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        options={counties.map((item) => ({
                          value: item.name,
                          label: item.name,
                        }))}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item label="Local" name="work_local">
                      <Input size="large" placeholder="Local" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[24]} className="ml-0 mr-0 mb-20">
                  <Col span={24}>
                    <Divider dashed style={{ borderColor: "#FFF" }} />
                    <p className="f-16 blue bold">Password</p>
                  </Col>
                </Row>
                <Row gutter={[24]} className="ml-0 mr-0">
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item hidden name="password">
                      <Input readOnly disabled />
                    </Form.Item>
                    <Form.Item label="Password atual" name="old_password">
                      <Input.Password size="large" placeholder="Password atual" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item label="Nova password" name="new_password">
                      <Input.Password size="large" placeholder="Nova password" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.new_password !== currentValues.new_password}>
                      {({ getFieldValue }) => (
                        <Form.Item
                          label="Confirmar nova password"
                          name="new_confirm_password"
                          dependencies={["new_password"]}
                          hasFeedback
                          rules={[
                            {
                              required: getFieldValue("new_password") ? true : false,
                              message: "Confirme a sua password, por favor!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue("new_password") === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error("As passwords não correspondem"));
                              },
                            }),
                          ]}
                        >
                          <Input.Password size="large" placeholder="Confirmar Password" />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[24]} className="ml-0 mr-0">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Button loading={isButtonLoading} type="primary" htmlType="submit" className="submit-btn mt-30" disabled={!isVerified}>
                      Guardar alterações
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="d-flex jc-end ai-center mt-30">
                    <div className="delete_button" onClick={handleDeleteAccount}>
                      Apagar conta
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default Profile;
