import React, { useState } from "react";
import { Checkbox, Form, Select, Upload, Input } from "antd";

import "../../../assets/styles/admin.css";
import "react-quill/dist/quill.snow.css";

function ChooseColumns({ form, data, handleSubmit }) {
  return (
    <div className="d-flex flex-column jc-center ai-center p-20">
      <p className="bold blue f-20 mb-30 mt-30">Escolha as colunas que deseja exportar</p>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item name="columns">
          <Checkbox.Group>
            {Object.keys(data[0]).map((item) => (
              <Checkbox value={item}>{item}</Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ChooseColumns;
